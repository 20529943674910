import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    mood: {
        show: true,
        title: 'Mood',
        description: `Children's Depression Inventory (CDI)`,
    },
    riskFactors: {
        show: true,
        title: 'Risk Factors',
        description: '(Higher Scores = Higher Risk)',
    },
    copingSkills: {
        show: true,
        title: 'Coping Skills',
        description: '(Higher Scores = Better Coping)',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <h4>Mood</h4>
            <Field name="mood.show" component={CccisdToggle} label="Show Mood?" />
            <Field name="mood.title" component={CccisdInput} label="Title" />
            <Field name="mood.description" component={CccisdInput} label="Description" />
            <h4>Teen Post-Assessment</h4>
            <Field name="riskFactors.show" component={CccisdToggle} label="Show Risk Factors?" />
            <Field name="riskFactors.title" component={CccisdInput} label="Title" />
            <Field name="riskFactors.description" component={CccisdInput} label="Description" />
            <h4>Coping Skills</h4>
            <Field name="copingSkills.show" component={CccisdToggle} label="Show Coping Skills?" />
            <Field name="copingSkills.title" component={CccisdInput} label="Title" />
            <Field name="copingSkills.description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
