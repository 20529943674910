import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    groupAttendanceRate: {
        show: true,
        title: 'Group Attendance Rate',
        description: '',
    },
    groupHomeworkRate: {
        show: true,
        title: 'Group Assignment Completion Rate',
        description: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <h4>Callout 1</h4>
            <Field
                name="groupAttendanceRate.show"
                component={CccisdToggle}
                label="Show Callout 1?"
            />
            <Field name="groupAttendanceRate.title" component={CccisdInput} label="Title" />
            <Field
                name="groupAttendanceRate.description"
                component={CccisdInput}
                label="Description"
            />
            <h4>Callout 2</h4>
            <Field name="groupHomeworkRate.show" component={CccisdToggle} label="Show Callout 2?" />
            <Field name="gorupHomeworkRate.title" component={CccisdInput} label="Title" />
            <Field
                name="groupHomeworkRate.description"
                component={CccisdInput}
                label="Description"
            />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
