import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { SessionDataEntry } from 'cccisd-laravel-assignment';

import classListQuery from './classList.graphql';

var Fortress = window.cccisd.fortress;

function SessionFidelity() {
    const [classList, setClassList] = useState([]);
    const [instructorList, setInstructorList] = useState();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const data = await getClassList();

        const formattedClassList = formatClassList(data.groups.classList);
        const formattedInstructorList = formatInstructorList(data.roles.instructorList);

        await setClassList(formattedClassList);
        await setInstructorList(formattedInstructorList);

        setIsLoading(false);
    }

    async function getClassList() {
        let groupId = Fortress.user.acting.group_id;
        let pawnId = Fortress.user.acting.id;
        const res = await apollo.query({
            query: classListQuery,
            fetchPolicy: 'network-only',
            variables: {
                groupId,
                pawnId,
            },
        });

        return res.data;
    }

    function formatClassList(list) {
        return list.map(
            ({
                group: { groupId, label },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                    learnerList,
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: getFlowProps(learnerList),
                };
            }
        );
    }

    function formatInstructorList(list) {
        return list.map(item => {
            return {
                id: item.pawn.pawnId,
                name: item.user.fullName,
            };
        });
    }

    function formatLearnerList(list) {
        return list.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.fields.firstName
                    ? `${item.fields.firstName} ${item.fields.lastName &&
                          `${item.fields.lastName.split('')[0]}.`}`
                    : item.fields.participantId || '',
            };
        });
    }

    function getFlowProps(learnerList) {
        return {
            variables: {
                providers_list: instructorList,
            },
            userLists: {
                participants_list: formatLearnerList(learnerList),
            },
        };
    }

    return (
        !isLoading && (
            <SessionDataEntry
                questprojectIds={[8]}
                groups={classList}
                description="Session Data supports data entry and collection at the session and client-level. Users may enter contact data (attendance), adherence and fidelity data for each session. To explore this data further, go to the Report Center to view corresponding reports."
            />
        )
    );
}

export default SessionFidelity;
