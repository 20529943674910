import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';

import siteListQuery from './siteList.graphql';

function ProviderOutcomes() {
    const [siteList, setSiteList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const data = await getSiteList();
        const formattedSiteList = formatSiteList(data.groups.siteList);

        await setSiteList(formattedSiteList);

        setIsLoading(false);
    }

    async function getSiteList() {
        const res = await apollo.query({
            query: siteListQuery,
            fetchPolicy: 'network-only',
        });

        return res.data;
    }

    function formatSiteList(list) {
        return list.map(
            ({
                group: { groupId, label },
                ancestorGroups: {
                    groupingUnit: {
                        group: { label: organization },
                    },
                },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: {
                        variables: {
                            organization,
                            site: label,
                        },
                    },
                };
            }
        );
    }

    return (
        !isLoading && (
            <OutcomesDataEntry
                questprojectIds={[7]}
                groups={siteList}
                description="Outcome assessments enable programs to explore the degree to which the services, content, and activities delivered to participants are having the desired impact. To explore this data further, go to the Report Center to view corresponding reports."
                tableColumns={[
                    {
                        name: 'fields.adminId',
                        label: 'Provider ID',
                        sort: true,
                        filter: true,
                    },
                    { name: 'user.firstName', label: 'First Name', sort: true, filter: true },
                    {
                        name: 'user.lastName',
                        label: 'Last Name',
                        sort: true,
                        filter: true,
                    },
                    {
                        name: 'user.username',
                        label: 'Username',
                        sort: true,
                        filter: true,
                    },
                ]}
                tableFields={['adminId']}
                modalTitleField="adminId"
            />
        )
    );
}

export default ProviderOutcomes;
