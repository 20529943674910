import React from 'react';
import PropTypes from 'prop-types';
import Callout from './Callout';
import _round from 'lodash/round';
import _mean from 'lodash/mean';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;

    const calloutsData = props.convertDataStructure(
        props.widgetProps,
        props.filters.classSelector[0]
    );

    const { fidelityAvg = 0, groupSatisfaction = 0, programSatisfaction = 0 } = calloutsData;

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    {props.settings.fidelityAvg.show && (
                        <Callout
                            handle="fidelityAvg"
                            settings={{
                                ...props.settings.fidelityAvg,
                                colors: props.settings.colors,
                            }}
                            data={{
                                value: fidelityAvg,
                            }}
                        />
                    )}
                    {props.settings.groupSatisfaction.show && (
                        <Callout
                            handle="groupSatisfaction"
                            settings={{
                                ...props.settings.groupSatisfaction,
                                colors: props.settings.colors,
                            }}
                            data={{
                                value: groupSatisfaction,
                            }}
                        />
                    )}
                    {props.settings.programSatisfaction.show && (
                        <Callout
                            handle="programSatisfaction"
                            settings={{
                                ...props.settings.programSatisfaction,
                                colors: props.settings.colors,
                            }}
                            data={{
                                value: programSatisfaction,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: (data, groupId) => {
        if (!groupId) {
            return {
                fidelityAvg: 0,
                groupSatisfaction: 0,
                programSatisfaction: 0,
            };
        }

        const { classList } = data ? data.groups : {};

        const group = groupId
            ? classList.find(g => g.childRoles.metricspawn.pawn.pawnId === Number(groupId))
            : {};
        const groupMembers = group.childRoles ? group.childRoles.learnerList : [];

        const AvgEngagement = _mean(
            groupMembers.map(gm => {
                return _mean([
                    Number(gm.s1.devTags.EngagementSum_1),
                    Number(gm.s4.devTags.EngagementSum_4),
                    Number(gm.s7.devTags.EngagementSum_7),
                ]);
            })
        );
        const AvgSettingFactor = _mean(
            groupMembers.map(gm => {
                return _mean([
                    Number(gm.s1.devTags.SettingFactor_1),
                    Number(gm.s4.devTags.SettingFactor_4),
                    Number(gm.s7.devTags.SettingFactor_7),
                ]);
            })
        );
        const AvgEffectiveStrategies = _mean(
            groupMembers.map(gm => {
                return _mean([
                    Number(gm.s1.devTags.EffectiveStrategies_1),
                    Number(gm.s4.devTags.EffectiveStrategies_4),
                    Number(gm.s7.devTags.EffectiveStrategies_7),
                ]);
            })
        );
        const AvgBuildingConnections = _mean(
            groupMembers.map(gm => {
                return _mean([
                    Number(gm.s1.devTags.BuildingConnections_1),
                    Number(gm.s4.devTags.BuildingConnections_4),
                    Number(gm.s7.devTags.BuildingConnections_7),
                ]);
            })
        );

        const groupSatisfactionAvgs = groupMembers.map(gm =>
            Number(gm.s10.devTags.Avg_GroupSatisfaction)
        );
        const programSatisfactionAvgs = groupMembers.map(gm =>
            Number(gm.s10.devTags.Avg_ProgramSatisfaction)
        );

        return {
            fidelityAvg:
                _round(
                    _mean([
                        AvgEngagement,
                        AvgSettingFactor,
                        AvgEffectiveStrategies,
                        AvgBuildingConnections,
                    ]),
                    1
                ) || 'N/A',
            groupSatisfaction: groupSatisfactionAvgs.length
                ? _round(_mean(groupSatisfactionAvgs, 1))
                : 'N/A',
            programSatisfaction: programSatisfactionAvgs.length
                ? _round(_mean(programSatisfactionAvgs, 1))
                : 'N/A',
        };
    },
};

export default Component;
