import React from 'react';
import PropTypes from 'prop-types';
import query from '../../class.graphql';
import Comments from './Comments';
import { Page } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery';

const Component = props => {
    const { show } = props.settings;
    const { filters } = props;

    const [data, loading] = useQuery({
        query: props.query || query,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const comments = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <>
                    {comments.map(c => (
                        <Page>
                            <Comments
                                settings={{ title: `${c.provider}'s Comments` }}
                                colors={props.settings.colors}
                                filters={filters}
                                data={c}
                            />
                        </Page>
                    ))}
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    convertDataStructure: PropTypes.func,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { assignmentProgressList } = (data && data.flows) || {};

        return assignmentProgressList.length > 0
            ? assignmentProgressList.map(ap => {
                  return {
                      provider: ap.provider.ProviderFullName,
                      '1': {
                          'Prep & Setup': ap.comments.Comments_PrepSetup_1,
                          'Session Content': ap.comments.Comments_SessionContent_1,
                          'Setting Factor': ap.comments.Comments_SettingFactor_1,
                          'Effective Strategies': ap.comments.Comments_EffectiveStrategies_1,
                          'Building Connections': ap.comments.Comments_BuildingConnections_1,
                      },
                      '4': {
                          'Prep & Setup': ap.comments.Comments_PrepSetup_4,
                          'Session Content': ap.comments.Comments_SessionContent_4,
                          'Setting Factor': ap.comments.Comments_SettingFactor_4,
                          'Effective Strategies': ap.comments.Comments_EffectiveStrategies_4,
                          'Building Connections': ap.comments.Comments_BuildingConnections_4,
                      },
                      '7': {
                          'Prep & Setup': ap.comments.Comments_PrepSetup_7,
                          'Session Content': ap.comments.Comments_SessionContent_7,
                          'Setting Factor': ap.comments.Comments_SettingFactor_7,
                          'Effective Strategies': ap.comments.Comments_EffectiveStrategies_7,
                          'Building Connections': ap.comments.Comments_BuildingConnections_7,
                      },
                  };
              })
            : [];
    },
};

export default Component;
