/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';

import Loader from 'cccisd-loader';

import IconComment from 'cccisd-icons/bubble-dots3';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';

import useQuery from '../../../../useQuery';
import style from './style.css';
import topFiveProblemsQuery from './topFiveProblems.graphql';

const Component = props => {
    const [boxOpen, setBoxOpen] = useState(true);
    const { show, title, description, colors } = props.settings;
    const { convertDataStructure, query, filters } = props;

    const [data, loading] = useQuery({
        query: query || topFiveProblemsQuery,
        variables: filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const { Problem1, Problem2, Problem3, Problem4, Problem5 } = convertDataStructure(data);

    return (
        <>
            {show && (
                <div
                    className={style.wrapper}
                    style={colors ? { borderColor: colors.primary } : {}}
                >
                    <div
                        className={style.header}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div>
                            <IconComment spaceRight />
                            {title}
                        </div>
                        <button
                            type="button"
                            className={`btn btn-sm ${style.menuButton}`}
                            onClick={() => setBoxOpen(!boxOpen)}
                            style={
                                colors ? { backgroundColor: Color(colors.primary).darken(0.2) } : {}
                            }
                        >
                            {boxOpen ? <IconMinus /> : <IconPlus />}
                        </button>
                    </div>
                    {boxOpen && (
                        <div className={style.commentWrapper}>
                            <p>{description}</p>
                            <p>
                                <strong>Problem 1: </strong>
                                {Problem1}
                            </p>
                            <p>
                                <strong>Problem 2: </strong>
                                {Problem2}
                            </p>
                            <p>
                                <strong>Problem 3: </strong>
                                {Problem3}
                            </p>
                            <p>
                                <strong>Problem 4: </strong>
                                {Problem4}
                            </p>
                            <p>
                                <strong>Problem 5: </strong>
                                {Problem5}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const learner = data.roles.learnerList[0];
        const {
            Problem1_1_2: Problem1 = '',
            Problem2_1_2: Problem2 = '',
            Problem3_1_2: Problem3 = '',
            Problem4_1_2: Problem4 = '',
            Problem5_1_2: Problem5 = '',
        } = learner ? learner.phase1_2.devTags : {};
        return {
            Problem1,
            Problem2,
            Problem3,
            Problem4,
            Problem5,
        };
    },
};

export default Component;
