import React from 'react';
import { Field, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    chart: {
        verticalScaleMin: 0,
        verticalScaleMax: 5,
        keys: ['Provider 1', 'Provider 2'],
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Chart?" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
