import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import IconComment from 'cccisd-icons/bubble-dots3';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';
import style from './style.css';

const Comment = props => {
    const { topic, comment } = props;

    return (
        <div className={style.commentWrapper}>
            <div className={style.info}>{topic}</div>
            <div className={style.commentBlocks}>
                <div className={style.commentBlock}>
                    <div className={style.comment}>{comment || 'No comments'}</div>
                </div>
            </div>
        </div>
    );
};

const Session = props => {
    const { sessionNumber, sessionComments, colors } = props;

    return (
        <div className={style.sessionWrapper}>
            <div className={style.head} style={colors ? { backgroundColor: colors.primary } : {}}>
                Session {sessionNumber}
            </div>
            <div className={style.comments}>
                {Object.entries(sessionComments).map(
                    s => s[1] && <Comment topic={s[0]} comment={s[1]} colors={colors} />
                )}
            </div>
        </div>
    );
};

const Component = props => {
    const [boxOpen, setBoxOpen] = useState(true);

    const { title } = props.settings;
    const { data, colors } = props;

    const _renderCommentBox = comments => {
        // eslint-disable-next-line no-unused-vars
        const { provider, ...sessions } = comments;
        return (
            <>
                {Object.entries(sessions).map(s => (
                    <Session sessionNumber={s[0]} sessionComments={s[1]} colors={colors} />
                ))}
            </>
        );
    };

    return (
        <div>
            <div className={style.wrapper} style={colors ? { borderColor: colors.primary } : {}}>
                <div
                    className={style.header}
                    style={colors ? { backgroundColor: colors.primary } : {}}
                >
                    <div>
                        <IconComment spaceRight />
                        {title}
                    </div>
                    <button
                        type="button"
                        className={`btn btn-sm ${style.menuButton}`}
                        onClick={() => setBoxOpen(!boxOpen)}
                        style={colors ? { backgroundColor: Color(colors.primary).darken(0.2) } : {}}
                    >
                        {boxOpen ? <IconMinus /> : <IconPlus />}
                    </button>
                </div>
                {boxOpen ? _renderCommentBox(data) : null}
            </div>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    data: PropTypes.array,
    isPreview: PropTypes.bool,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colors: PropTypes.object,
    convertDataStructure: PropTypes.func,
    filters: PropTypes.object,
};

export default Component;
