/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import Chart from './Chart';
import chartsQuery from '../../class.graphql';
import style from './style.css';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { show, chart } = props.settings;
    const { query } = props;

    const [data, loading] = useQuery({
        query: query || chartsQuery,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const {
        provider1 = 'N/A',
        provider2 = 'N/A',
        provider1Data = [],
        provider2Data = [],
    } = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <>
                    <h1 className={style.title}>Core Domain Ratings</h1>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>{provider1}</h2>
                                    <Chart
                                        data={provider1Data}
                                        settings={{
                                            ...chart,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>{provider2}</h2>
                                    <Chart
                                        data={provider2Data}
                                        settings={{
                                            ...chart,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { assignmentProgressList } = data ? data.flows : {};

        function getChartData({
            PrepSetup_1,
            PrepSetup_4,
            PrepSetup_7,
            SessionContent_1,
            SessionContent_4,
            SessionContent_7,
            SettingFactor_1,
            SettingFactor_4,
            SettingFactor_7,
            EffectiveStrategies_1,
            EffectiveStrategies_4,
            EffectiveStrategies_7,
            BuildingConnections_1,
            BuildingConnections_4,
            BuildingConnections_7,
        }) {
            return [
                {
                    id: 'Preparation & Setup',
                    'Session 1': PrepSetup_1,
                    'Session 4': PrepSetup_4,
                    'Session 7': PrepSetup_7,
                },
                {
                    id: 'Session Content',
                    'Session 1': SessionContent_1,
                    'Session 4': SessionContent_4,
                    'Session 7': SessionContent_7,
                },
                {
                    id: 'Setting Factor',
                    'Session 1': SettingFactor_1,
                    'Session 4': SettingFactor_4,
                    'Session 7': SettingFactor_7,
                },
                {
                    id: 'Effective Strategies',
                    'Session 1': EffectiveStrategies_1,
                    'Session 4': EffectiveStrategies_4,
                    'Session 7': EffectiveStrategies_7,
                },
                {
                    id: 'Building Connections',
                    'Session 1': BuildingConnections_1,
                    'Session 4': BuildingConnections_4,
                    'Session 7': BuildingConnections_7,
                },
            ];
        }

        return {
            provider1:
                assignmentProgressList[0] && assignmentProgressList[0].provider.ProviderFullName,
            provider2:
                assignmentProgressList[1] && assignmentProgressList[1].provider.ProviderFullName,
            provider1Data: assignmentProgressList[0] && getChartData(assignmentProgressList[0].gs),
            provider2Data: assignmentProgressList[1] && getChartData(assignmentProgressList[1].gs),
        };
    },
};

export default Component;
