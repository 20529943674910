import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import calloutsQuery from '../../class.graphql';
import Callout from './Callout';
import _round from 'lodash/round';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;
    const { query } = props;

    const [data, loading] = useQuery({
        query: query || calloutsQuery,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const {
        provider1 = 'N/A',
        provider2 = 'N/A',
        provider1Score = 0,
        provider2Score = 0,
    } = calloutsData;

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    {props.settings.provider1Score.show && (
                        <Callout
                            handle="provider1Score"
                            settings={{
                                ...props.settings.provider1Score,
                                colors: props.settings.colors,
                                title: provider1,
                            }}
                            data={{
                                value: provider1Score,
                            }}
                        />
                    )}
                    {props.settings.provider2Score.show && (
                        <Callout
                            handle="provider2Score"
                            settings={{
                                ...props.settings.provider2Score,
                                colors: props.settings.colors,
                                title: provider2,
                            }}
                            data={{
                                value: provider2Score,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { assignmentProgressList } = data ? data.flows : {};

        function calcAvgGlobalScore(tags) {
            const numerator = Object.values(tags).reduce((a, c) => {
                const val = Number(c) || 0;
                return a + val;
            }, 0);

            const denominator = Object.values(tags).length;

            return _round(numerator / denominator, 1);
        }

        return {
            provider1:
                assignmentProgressList[0] && assignmentProgressList[0].provider.ProviderFullName,
            provider2:
                assignmentProgressList[1] && assignmentProgressList[1].provider.ProviderFullName,
            provider1Score:
                assignmentProgressList[0] && calcAvgGlobalScore(assignmentProgressList[0].gs),
            provider2Score:
                assignmentProgressList[1] && calcAvgGlobalScore(assignmentProgressList[1].gs),
        };
    },
};

export default Component;
