import React from 'react';
import style from './style.css';

const ShowValue = props => {
    const { colors } = props;

    const getRiskLevelColor = level => {
        if (level === 'MEDIUM') return 'orange';
        if (level === 'HIGH') return 'red';
        return 'inherit';
    };

    return (
        <div className={style.wrapper} style={colors ? { color: colors.primary } : {}}>
            {Object.values(props.data).map(v => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontSize: '12px' }}>{v.label}</div>
                        <div>
                            <div style={{ fontSize: '24px', color: getRiskLevelColor(v.value) }}>
                                {v.value}
                            </div>
                            <div style={{ fontSize: '8px' }}>{v.descriptor}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ShowValue;
