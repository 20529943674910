import React from 'react';
import { Field, CccisdInput, CccisdToggle, CccisdTextarea } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: 'Top Five Problems',
    description:
        'What are the main problems you are having? What are the reasons why you sometimes feel stresses, bad, or upset? We would like to know at least three of the most important things you wish were different or would like to change. What problems are you having that the Act & Adapt program could help with?',
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Comments?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdTextarea} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
