import React from 'react';

import Modal from 'cccisd-modal';
import { Formik, Form, Field, CccisdInput } from 'cccisd-formik';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';

import IconEdit from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd.boilerplate;

async function handleSubmit({ pawnId, pawnHash, values, closeModal, loadData }) {
    await axios.put(
        Boilerplate.route('api.nexus.pawn.update', {
            pawn: pawnId,
        }),
        {
            pawnHash,
            potentialTreatmentGroup: values.ptg,
        }
    );

    closeModal && closeModal();
    loadData && loadData();
}

export default function PotentialTreatmentGroupModal({
    loadData,
    row: {
        'pawn.pawnId': pawnId,
        'pawn.pawnHash': pawnHash,
        'fields.potentialTreatmentGroup': ptg,
    },
}) {
    return (
        <Modal
            title="Assign a Potential Treatment Group"
            trigger={
                <button type="button" className="btn btn-xs btn-link">
                    {ptg || (
                        <span>
                            <IconEdit spaceRight />
                            Assign
                        </span>
                    )}
                </button>
            }
            render={({ closeModal }) => {
                return (
                    <Formik
                        onSubmit={values =>
                            handleSubmit({ pawnId, pawnHash, values, closeModal, loadData })
                        }
                        initialValues={{ ptg }}
                    >
                        {({ isSubmitting, submitForm }) => (
                            <Form>
                                <section>
                                    <Field
                                        component={CccisdInput}
                                        name="ptg"
                                        label="Treatment Group:"
                                    />
                                </section>
                                <section
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '1em',
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() => closeModal()}
                                    >
                                        Cancel
                                    </button>
                                    <Button
                                        title="Submit"
                                        type="submit"
                                        isLoading={isSubmitting}
                                        className="btn btn-primary"
                                        onClick={() => submitForm()}
                                    />
                                </section>
                            </Form>
                        )}
                    </Formik>
                );
            }}
        />
    );
}
