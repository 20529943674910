import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import AppDefs, { AdminRoutes, Dashboard as AppdefDashboard, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest, ViewData } from 'cccisd-laravel-assignment';
import { ResourceCenter } from 'cccisd-laravel-resources';
import ReportCenter from 'cccisd-report-center';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import DeploymentEvaluator from 'bundle-loader?lazy!./pages/DeploymentEvaluator';
import ScreeningEvaluator from 'bundle-loader?lazy!./pages/ScreeningEvaluator';

// Components
import Referrals from 'bundle-loader?lazy!./components/Referrals';
import SessionData from 'bundle-loader?lazy!./components/SessionData';
import SessionFidelity from 'bundle-loader?lazy!./components/SessionFidelity';
import OutcomesData from 'bundle-loader?lazy!./components/OutcomesData';
import ProviderOutcomes from 'bundle-loader?lazy!./components/ProviderOutcomes';
import GroupFidelity from 'bundle-loader?lazy!./components/GroupFidelity';
// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    AppdefDashboard,
    Welcome,
    Referrals,
    SessionData,
    SessionFidelity,
    OutcomesData,
    ProviderOutcomes,
    GroupFidelity,
    ReportCenter,
    ResourceCenter,
    ViewData,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            if (route.roleDataFields) {
                const hasAccess = route.roleDataFields.every(field => Fortress.user.acting.role_data[field]);

                if (!hasAccess) {
                    return null;
                }
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} />

        <AppRoute
            exact
            path="/r/:siteId"
            component={ScreeningEvaluator}
            componentProps={{
                errorLayout: MainLayout,
            }}
            layout={MainLayout}
        />
        <AppRoute
            exact
            path="/d/:deploymentHash/:respondentHash"
            component={DeploymentEvaluator}
            componentProps={{
                errorLayout: MainLayout,
            }}
            layout={MainLayout}
        />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluidSecondaryNav)}
        {Fortress.hasAccess('appdefs.admin') && (
            <AppRoute path="/appdefs" component={AppDefs} layout={MainLayoutFluidSecondaryNav} />
        )}

        {Fortress.auth() && (Fortress.user.acting.role.handle === 'orgAdmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/viewData" component={ViewData} />
        )}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
