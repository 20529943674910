import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const MyResponsiveBar = props => {
    const { keys, padding = 0.3, margin, tickValues, legends } = props.settings;

    return (
        <ResponsiveBar
            layout="horizontal"
            data={props.data}
            keys={keys}
            padding={padding}
            margin={margin}
            enableLabel={false}
            groupMode="grouped"
            axisBottom={{
                tickValues,
            }}
            enableGridY={false}
            enableGridX
            gridXValues={tickValues}
            colors={['#0C3C60', '#FFB293']}
            layers={['grid', 'axes', 'bars', 'markers', 'legends']}
            legends={legends}
        />
    );
};

export default MyResponsiveBar;
