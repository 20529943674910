import React from 'react';
import PropTypes from 'prop-types';
import { Field, CccisdInput, CccisdWysiwyg } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import ColorPicker from '../../components/ColorPicker';
import filterFields from '../filterFields.js';
import widgets from '../widgets/index.js';

export const initialValues = {
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    colors: { primary: '#3673b6' },
    header: {
        title: 'Youth Fidelity Report',
        subtitle: 'SCHOOL',
        groupText: 'Group:',
        providersText: 'Group Leader:',
        groupMemberCountText: 'Number of Group Members:',
        createdDateText: 'Report Created On:',
        description: '',
    },
    body: {
        heading: '',
    },
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = props => {
    return (
        <div>
            <BuilderBlock title="Header">
                <Field name="header.title" component={CccisdInput} label="Title" />
                <Field name="header.subtitle" component={CccisdInput} label="Subtitle" />
                <Field name="header.description" component={CccisdInput} label="Description" />
                <Field
                    name="header.createdDateText"
                    component={CccisdInput}
                    label="Created Date Text"
                />
                <BuilderBlock title="Subheader">
                    <Field name="header.groupText" component={CccisdInput} label="Group Text" />
                    <Field
                        name="header.providersText"
                        component={CccisdInput}
                        label="Provider(s) Text"
                    />
                    <Field
                        name="header.groupMemberCountText"
                        component={CccisdInput}
                        label="Number of Group Members Text:"
                    />
                </BuilderBlock>
            </BuilderBlock>
            <BuilderBlock title="Body">
                <Field name="body.heading" component={CccisdWysiwyg} label="Page Heading" />
            </BuilderBlock>
            <BuilderBlock title="Color">
                <Field name="colors.primary">
                    {({ field, form }) => {
                        return <ColorPicker field={field} form={form} />;
                    }}
                </Field>
            </BuilderBlock>
        </div>
    );
};

Component.propTypes = {
    onValidChange: PropTypes.func,
    settings: PropTypes.object,
};

export default reportTemplateBuilder({
    filterFields,
    widgets,
})(Component);
