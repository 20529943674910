const Fortress = window.cccisd.fortress;

const { role = {}, group = {}, id } = Fortress.user.acting;
const roleHandle = role.handle || null;
const groupId = group ? group.id : null;
const pawnId = id || null;

export function getPermissions() {
    return roleHandle === 'instructor' ? ['rostering.group.*'] : null;
}

export function getPermissionFilter() {
    if (roleHandle === 'orgAdmin') {
        return null;
    }

    if (roleHandle === 'siteAdmin') {
        return {
            eq: {
                field: 'parentGroup.site.group.groupId',
                int: groupId,
            },
        };
    }

    return {
        AND: [
            {
                eq: {
                    field: 'targetPermission.pawn.pawn.pawnId',
                    int: pawnId,
                },
            },
            { notNull: { field: 'targetPermission.permission' } },
        ],
    };
}
