import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import Table from 'cccisd-graphql-table';
import Tabs from 'cccisd-tabs';
import StyleBible from 'cccisd-style-bible';
import Loader from 'cccisd-loader';
import { client as apollo } from 'cccisd-apollo';

import IconClipboard from 'cccisd-icons/copy';
import IconEyeOpen from 'cccisd-icons/eye';
import IconEyeClose from 'cccisd-icons/eye-blocked';

import ReferralCreateModal from './components/ReferralCreateModal';
import pendingQuery from './pending.graphql';
import includedQuery from './included.graphql';
import excludedQuery from './excluded.graphql';
import phase1Query from './phase1.graphql';
import useColumns from './useColumns';
import sitesQuery from './sites.graphql';
import setClipboardText from './setClipboardText';
import style from './style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;
const role = Fortress.user.acting.role.handle;
const groupId = Fortress.user.acting.group.id;

function TabContent({ tab, group, phase1DeploymentHash }) {
    const pendingTableRef = useRef();
    const excludedTableRef = useRef();
    const columns = useColumns({ phase1DeploymentHash, pendingTableRef, excludedTableRef });
    const [showExcludedTable, setShowExcludedTable] = useState(false);

    return (
        <div style={{ padding: '2rem' }}>
            {tab === 'pending' && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            type="button"
                            className="btn btn-default"
                            style={{ marginRight: '1em' }}
                            onClick={() => setClipboardText(Boilerplate.url(`/r/${group.groupId}`))}
                        >
                            <IconClipboard /> Copy Referral Link For This Site
                        </button>
                        <ReferralCreateModal
                            siteId={group.groupId}
                            loadData={() => pendingTableRef.current.loadData()}
                        />
                    </div>
                    <Table
                        ref={pendingTableRef}
                        rowKey="pawn.pawnId"
                        query={pendingQuery}
                        graphqlVariables={{ siteId: group.groupId }}
                        columns={columns.pending}
                        additionalClassName={classnames(style.referralTable, 'text-center')}
                    />
                    <section>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                type="button"
                                onClick={() => setShowExcludedTable(!showExcludedTable)}
                                className="btn btn-link"
                            >
                                {showExcludedTable ? (
                                    <IconEyeClose spaceRight />
                                ) : (
                                    <IconEyeOpen spaceRight />
                                )}
                                {`${showExcludedTable ? 'Hide' : 'Show'} archived referrals`}
                            </button>
                        </div>
                        {showExcludedTable && (
                            <>
                                <hr />
                                <Table
                                    ref={excludedTableRef}
                                    rowKey="pawn.pawnId"
                                    query={excludedQuery}
                                    graphqlVariables={{ siteId: group.groupId }}
                                    columns={columns.excluded}
                                    additionalClassName={classnames(
                                        style.referralTable,
                                        'text-center'
                                    )}
                                />
                            </>
                        )}
                    </section>
                </>
            )}
            {tab === 'included' && (
                <Table
                    rowKey="pawn.pawnId"
                    query={includedQuery}
                    graphqlVariables={{ siteId: group.groupId }}
                    columns={columns.included}
                    additionalClassName={classnames(style.referralTable, 'text-center')}
                />
            )}
        </div>
    );
}

function Referrals() {
    const [phase1DeploymentHash, setPhase1DeploymentHash] = useState();
    const [sites, setSites] = useState();
    const [site, setSite] = useState();
    const tabList = [
        {
            name: 'pending',
            title: 'Pending',
        },
        {
            name: 'included',
            title: 'Included',
        },
    ];

    useEffect(() => {
        getPhase1DeploymentHash();
        if (role === 'orgAdmin') {
            getSites();
        }
        if (role === 'instructor' || role === 'siteAdmin') {
            setSite({ groupId });
        }
    }, []);

    async function getPhase1DeploymentHash() {
        await apollo
            .query({
                query: phase1Query,
                fetchPolicy: 'network-only',
            })
            .then(
                ({
                    data: {
                        flows: {
                            deployment: { hash },
                        },
                    },
                }) => {
                    setPhase1DeploymentHash(hash);
                }
            );
    }

    async function getSites() {
        await apollo
            .query({
                query: sitesQuery,
                fetchPolicy: 'network-only',
                variables: {
                    groupId,
                },
            })
            .then(
                ({
                    data: {
                        groups: { siteList },
                    },
                }) => {
                    const mapSiteList = siteList.map(({ group }) => {
                        return group;
                    });
                    setSites(mapSiteList);
                    setSite(mapSiteList[0]);
                }
            );
    }

    if (!site || !phase1DeploymentHash) {
        return <Loader isLoading />;
    }

    return (
        <div style={{ margin: '1em' }}>
            <h1>
                <strong>Referrals</strong>
            </h1>

            {role === 'orgAdmin' && (
                <div style={{ marginTop: '1rem' }}>
                    <strong>Select Site:</strong>
                    <select
                        className="form-control"
                        style={{ width: '30rem' }}
                        onChange={e => setSite({ groupId: e.target.value })}
                    >
                        {sites.map(option => (
                            <option key={option.groupId} value={option.groupId}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <div className={StyleBible.impactTabs1} style={{ marginTop: '1em' }}>
                <Tabs
                    tabList={tabList.map(({ name, title }) => {
                        return {
                            name,
                            title,
                            content: (
                                <TabContent
                                    tab={name}
                                    group={site}
                                    phase1DeploymentHash={phase1DeploymentHash}
                                />
                            ),
                        };
                    })}
                    saveInHash
                />
            </div>
        </div>
    );
}

export default Referrals;
