export default function useScoreLevel(row, scoreTag) {
    switch (scoreTag) {
        // Phase 1.1 cases
        case 'RCADS_AnxietySum': {
            let labelType;
            let risk = row['phase1_1.devTags.RCADS_AnxietyRisk'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_1.devTags.RCADS_AnxietySum'],
                level: row['phase1_1.devTags.RCADS_AnxietyRisk_Category'],
                labelType,
            };
        }

        case 'RCADS_DepSum': {
            let labelType;
            let risk = row['phase1_1.devTags.RCADS_DepRisk'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_1.devTags.RCADS_DepSum'],
                level: row['phase1_1.devTags.RCADS_DepRisk_Category'],
                labelType,
            };
        }

        case 'RCADS_PhobiaSum': {
            let labelType;
            let risk = row['phase1_1.devTags.RCADS_PhobiaRisk'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_1.devTags.RCADS_PhobiaSum'],
                level: row['phase1_1.devTags.RCADS_PhobiaRisk_Category'],
                labelType,
            };
        }

        case 'CDI_Sum': {
            let labelType;
            let risk = row['phase1_1.devTags.CDI_Risk'];
            if (risk === '0' || risk === '1') {
                labelType = 'default';
            }
            if (risk === '2') {
                labelType = 'warning';
            }
            if (risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_1.devTags.CDI_Sum'],
                level: row['phase1_1.devTags.CDI_Risk_Category'],
                labelType,
            };
        }

        case 'SDQ_CP_Sum': {
            let labelType;
            let risk = row['phase1_1.devTags.SDQ_Conduct_1_1'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2' || risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_1.devTags.SDQ_CP_Sum'],
                level: row['phase1_1.devTags.SDQ_Conduct_Cat_1_1'],
                labelType,
            };
        }

        // Phase 1.2 cases
        case 'SDQ_Emototal_1_2': {
            let labelType;
            let risk = row['phase1_2.devTags.SDQ_Emotional_1_2'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2' || risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_2.devTags.SDQ_Emototal_1_2'],
                level: row['phase1_2.devTags.SDQ_Emotional_Cat_1_2'],
                labelType,
            };
        }

        case 'SDQ_Peertotal_1_2': {
            let labelType;
            let risk = row['phase1_2.devTags.SDQ_Peer_1_2'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2' || risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_2.devTags.SDQ_Peertotal_1_2'],
                level: row['phase1_2.devTags.SDQ_Peer_Cat_1_2'],
                labelType,
            };
        }

        // Phase 1.3 cases
        case 'RCADS_AnxietySum_1_3': {
            let labelType;
            let risk = row['phase1_3.devTags.RCADS_AnxietyRisk_1_3'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_3.devTags.RCADS_AnxietySum_1_3'],
                level: row['phase1_3.devTags.RCADS_AnxietyRisk_Category_1_3'],
                labelType,
            };
        }

        case 'RCADS_DepSum_1_3': {
            let labelType;
            let risk = row['phase1_3.devTags.RCADS_DepRisk_1_3'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_3.devTags.RCADS_DepSum_1_3'],
                level: row['phase1_3.devTags.RCADS_DepRisk_Category_1_3'],
                labelType,
            };
        }

        case 'RCADS_PhobiaSum_1_3': {
            let labelType;
            let risk = row['phase1_3.devTags.RCADS_PhobiaRisk_1_3'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_3.devTags.RCADS_PhobiaSum_1_3'],
                level: row['phase1_3.devTags.RCADS_PhobiaRisk_Category_1_3'],
                labelType,
            };
        }

        case 'CDI_Sum_1_3': {
            let labelType;
            let risk = row['phase1_3.devTags.CDI_Risk_1_3'];
            if (risk === '0' || risk === '1') {
                labelType = 'default';
            }
            if (risk === '2') {
                labelType = 'warning';
            }
            if (risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_3.devTags.CDI_Sum_1_3'],
                level: row['phase1_3.devTags.CDI_Risk_Category_1_3'],
                labelType,
            };
        }

        case 'SDQ_CP_Sum_1_3': {
            let labelType;
            let risk = row['phase1_3.devTags.SDQ_Conduct_1_3'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2' || risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_3.devTags.SDQ_CP_Sum_1_3'],
                level: row['phase1_3.devTags.SDQ_Conduct_Cat_1_3'],
                labelType,
            };
        }

        case 'SDQ_Emototal_1_3': {
            let labelType;
            let risk = row['phase1_3.devTags.SDQ_Emotional_1_3'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2' || risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_3.devTags.SDQ_Emototal_1_3'],
                level: row['phase1_3.devTags.SDQ_Emotional_Cat_1_3'],
                labelType,
            };
        }

        case 'SDQ_Peertotal_1_3': {
            let labelType;
            let risk = row['phase1_3.devTags.SDQ_Peer_1_3'];
            if (risk === '0') {
                labelType = 'default';
            }
            if (risk === '1') {
                labelType = 'warning';
            }
            if (risk === '2' || risk === '3') {
                labelType = 'danger';
            }
            return {
                score: row['phase1_3.devTags.SDQ_Peertotal_1_3'],
                level: row['phase1_3.devTags.SDQ_Peer_Cat_1_3'],
                labelType,
            };
        }

        default: {
            return null;
        }
    }
}
