import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import calloutsQuery from '../../class.graphql';
import Callout from './Callout';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;
    const { query } = props;

    const [data, loading] = useQuery({
        query: query || calloutsQuery,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const {
        groupAttendanceRate = 0,
        targetGroupAttendanceRate = 0,
        groupHomeworkRate = 0,
        targetGroupHomeworkRate = 0,
    } = calloutsData;

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    {props.settings.groupAttendanceRate.show && (
                        <Callout
                            handle="groupAttendanceRate"
                            settings={{
                                ...props.settings.groupAttendanceRate,
                                colors: props.settings.colors,
                            }}
                            data={{
                                value: groupAttendanceRate,
                                target: targetGroupAttendanceRate,
                            }}
                        />
                    )}
                    {props.settings.groupHomeworkRate.show && (
                        <Callout
                            handle="groupHomeworkRate"
                            settings={{
                                ...props.settings.groupHomeworkRate,
                                colors: props.settings.colors,
                            }}
                            data={{
                                value: groupHomeworkRate,
                                target: targetGroupHomeworkRate,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { classList } = data ? data.groups : {};
        const groupData = classList.length > 0 && classList[0].fields;

        const groupAttendanceRate = groupData.groupAttendanceRate;
        const targetGroupAttendanceRate = 80;

        const groupHomeworkRate = groupData.groupHomeworkRate;
        const targetGroupHomeworkRate = 80;

        return {
            groupAttendanceRate,
            targetGroupAttendanceRate,
            groupHomeworkRate,
            targetGroupHomeworkRate,
        };
    },
};

export default Component;
