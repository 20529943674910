import calloutsGlobalScore from './calloutsGlobalScore';
import chartsCoreDomainRatings from './chartsCoreDomainRatings';
import chartsModelImplementation from './chartsModelImplementation';
import calloutsSatisfaction from './calloutsSatisfaction';
import chartsSatisfaction from './chartsSatisfaction';
import commentsFidelity from './commentsFidelity';

export default [
    calloutsGlobalScore,
    chartsCoreDomainRatings,
    chartsModelImplementation,
    calloutsSatisfaction,
    chartsSatisfaction,
    commentsFidelity,
];
