import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import Loader from 'cccisd-loader';
import IconStars from 'cccisd-icons/stars';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';
import style from './style.css';
import query from '../../../class.graphql';
import useQuery from '../../../../../useQuery';

const PrizeList = props => {
    const { totalPrizes, prizes } = props;

    return (
        <div className={style.commentWrapper}>
            <div className={style.info}>{totalPrizes || 0} Total Prizes:</div>
            <div className={style.commentBlocks}>
                {prizes.map(p => {
                    return (
                        <div className={style.commentBlock}>
                            <div className={style.comment}>{p}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const GroupMember = props => {
    const { learner, colors } = props;

    return (
        <div className={style.sessionWrapper}>
            <div className={style.head} style={colors ? { backgroundColor: colors.primary } : {}}>
                {learner.fields.firstName}
            </div>
            <div className={style.comments}>
                <PrizeList
                    totalPrizes={learner.subtotals.PrizesSum}
                    prizes={learner.assignmentProgressList.map(ap => ap.devTags.PrizeDescription)}
                    colors={colors}
                />
            </div>
        </div>
    );
};

const Component = props => {
    const [boxOpen, setBoxOpen] = useState(true);

    const { title } = props.settings;
    const { colors } = props;

    const [data, loading] = useQuery({
        query: props.query || query,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const _renderCommentBox = learners => {
        return learners.map((learner, i) => {
            return <GroupMember key={i} learner={learner} colors={colors} />;
        });
    };

    return (
        <div>
            <div className={style.wrapper} style={colors ? { borderColor: colors.primary } : {}}>
                <div
                    className={style.header}
                    style={colors ? { backgroundColor: colors.primary } : {}}
                >
                    <div>
                        <IconStars spaceRight />
                        {title}
                    </div>
                    <button
                        type="button"
                        className={`btn btn-sm ${style.menuButton}`}
                        onClick={() => setBoxOpen(!boxOpen)}
                        style={colors ? { backgroundColor: Color(colors.primary).darken(0.2) } : {}}
                    >
                        {boxOpen ? <IconMinus /> : <IconPlus />}
                    </button>
                </div>
                {boxOpen ? _renderCommentBox(props.convertDataStructure(data)) : null}
            </div>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    data: PropTypes.array,
    isPreview: PropTypes.bool,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colors: PropTypes.object,
    convertDataStructure: PropTypes.func,
    filters: PropTypes.object,
};

Component.defaultProps = {
    convertDataStructure: data => {
        const groups = (data && data.groups.classList) || [];
        const learners = (groups.length > 0 && groups[0].childRoles.learnerList) || [];

        return learners;
    },
};

export default Component;
