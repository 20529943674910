import React from 'react';
import { Field, CccisdToggle, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    pageTitle: 'Average Group Scores',
    depressionSymptoms: {
        show: true,
        title: 'Depression Symptoms Average Scores',
    },
    overallGroupRating: {
        show: true,
        title: 'Group Member Program Satisfaction',
    },
    odgm: {
        show: true,
        title: 'Conduct Problems Group Average Scores',
    },
    itemSectionTitle: 'Average Program Satisfaction Group Scores',
};

const Component = props => {
    return (
        <div>
            <Field
                name="show"
                component={CccisdToggle}
                label="Show Average Group Scores Callouts?"
            />
            <Field name="pageTitle" component={CccisdWysiwyg} label="Page Title" />
            <hr />
            <h4>Depression Symptoms</h4>
            <Field
                name="depressionSymptoms.show"
                component={CccisdToggle}
                label="Show Depression Symptoms?"
            />
            <Field name="depressionSymptoms.title" component={CccisdWysiwyg} label="Title" />
            <h4>Program Satisfaction Overall Group Rating</h4>
            <Field
                name="overallGroupRating.show"
                component={CccisdToggle}
                label="Show Program Satisfaction Overall Group Rating?"
            />
            <Field name="overallGroupRating.title" component={CccisdWysiwyg} label="Title" />
            <h4>Oppositional Defiant Group Means</h4>
            <Field
                name="odgm.show"
                component={CccisdToggle}
                label="Show Oppositional Defiant Group Means?"
            />
            <Field name="odgm.title" component={CccisdWysiwyg} label="Title" />
            <hr />
            <Field
                name="itemSectionTitle"
                component={CccisdWysiwyg}
                label="Individual Items Section Title"
            />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
