import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

import siteQuery from './site.graphql';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

NewReferralPlayer.propTypes = {
    siteId: PropTypes.number.isRequired,
    /** The layout to use for the 404 page */
    errorLayout: PropTypes.func,
};

function NewReferralPlayer({ siteId, errorLayout }) {
    const [isLoading, setIsLoading] = useState(true);
    const [show404, setShow404] = useState(false);
    const [pawn, setPawn] = useState(null);
    const [groupLabel, setGroupLabel] = useState(null);

    useEffect(() => {
        evaluateDeployment();
    }, []);

    const evaluateDeployment = async () => {
        if (!siteId) {
            setShow404(true);
            setIsLoading(false);
            return;
        }

        await apollo
            .query({
                query: siteQuery,
                fetchPolicy: 'network-only',
                variables: {
                    siteId,
                },
            })
            .then(async res => {
                const { groupId, label } = res.data.groups.site.group;

                if (!groupId) {
                    setShow404(true);
                    setIsLoading(false);
                    return;
                }

                try {
                    const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
                        pending: true,
                        group: groupId,
                        role: 'learner',
                    });
                    await setGroupLabel(label);
                    await setPawn({
                        pawnId: response.data.data.id,
                        pawnHash: response.data.data.random_hash,
                    });
                } catch (error) {
                    console.error(error);
                    setShow404(true);
                }
            })
            .catch(err => {
                console.error(err);
                setShow404(true);
            });
        await setIsLoading(false);
    };

    if (isLoading) {
        return <Loader loading />;
    }

    if (show404) {
        if (errorLayout) {
            let ErrorLayout = errorLayout;
            return (
                <ErrorLayout>
                    <NotFound />
                </ErrorLayout>
            );
        }
        return <NotFound />;
    }

    return (
        pawn && (
            <div style={{ marginTop: '2rem' }}>
                <DeploymentPlayer
                    deploymentHandle="screening"
                    pawnId={pawn.pawnId}
                    pawnHash={pawn.pawnHash}
                    flowProps={{
                        hidePlayButton: true,
                        variables: {
                            site: groupLabel,
                        },
                    }}
                    disableLayout
                />
            </div>
        )
    );
}

export default NewReferralPlayer;
