import React from 'react';

import IconClipboard from 'cccisd-icons/copy';

import setClipboardText from '../../components/Referrals/setClipboardText';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default () => {
    return ({ row: { 'group.groupId': groupId } }) => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-default"
                style={{ marginRight: '1em' }}
                onClick={() => setClipboardText(Boilerplate.url(`/r/${groupId}`))}
            >
                <IconClipboard />
            </button>
        );
    };
};
