import React from 'react';
import PropTypes from 'prop-types';

import NewReferralPlayer from '../../components/Referrals/components/NewReferralPlayer';

ScreeningEvaluator.propTypes = {
    /** The layout to use for the 404 page */
    errorLayout: PropTypes.func,
    /* From redux-router */
    match: PropTypes.object.isRequired,
};

function ScreeningEvaluator({
    errorLayout = null,
    match: {
        params: { siteId },
    },
}) {
    return (
        <div style={{ marginTop: '2rem' }}>
            <NewReferralPlayer siteId={siteId} errorLayout={errorLayout} />
        </div>
    );
}

export default ScreeningEvaluator;
