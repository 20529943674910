import React from 'react';
import moment from 'moment';

import Modal from 'cccisd-modal';

import IconView from 'cccisd-icons/eye2';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const Fortress = window.cccisd && window.cccisd.fortress;
const role = Fortress.user.acting.role.handle;

export default function ReferralViewModal({
    row: {
        'pawn.pawnId': pawnId,
        'pawn.pawnHash': pawnHash,
        'screening.completedDate': completedDate,
        'ancestorGroups.site.group.label': site,
    },
    loadData,
    renderTrigger = ({ onClick }) => (
        <button type="button" className="btn btn-xs btn-primary" onClick={onClick}>
            <IconView spaceRight />
            <span>{`${moment(completedDate).format('MM/DD/YYYY')}`}</span>
        </button>
    ),
    tableType = 'pending',
}) {
    return tableType === 'excluded' ||
        (tableType === 'pending' && role !== 'instructor' && role !== 'siteAdmin') ? (
        <span>{`${moment(completedDate).format('MM/DD/YYYY')}`}</span>
    ) : (
        <Modal
            renderTrigger={renderTrigger}
            size="80%"
            bodyStyle={{
                overflow: 'scroll',
            }}
            beforeClose={() => loadData()}
        >
            <DeploymentPlayer
                disableLayout
                deploymentHandle="screening"
                pawnId={pawnId}
                pawnHash={pawnHash}
                flowProps={{
                    variables: {
                        site,
                    },
                }}
            />
        </Modal>
    );
}
