import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import color from 'color';

const MyResponsiveBar = props => {
    const {
        keys,
        padding = 0.3,
        margin,
        verticalScaleMin,
        verticalScaleMax,
        tickValues,
        legends,
    } = props.settings;

    return (
        <ResponsiveBar
            layout="horizontal"
            data={props.data}
            keys={keys}
            padding={padding}
            margin={margin}
            minValue={Number(verticalScaleMin)}
            maxValue={Number(verticalScaleMax)}
            enableLabel={false}
            groupMode="grouped"
            axisBottom={{
                tickValues,
            }}
            enableGridY={false}
            enableGridX
            gridXValues={tickValues}
            colors={[
                '#0C3C60',
                '#2087C7',
                '#FFB293',
                '#AFCBE0',
                color('#FFB293')
                    .lighten(0.175)
                    .hex(),
                color('#AFCBE0')
                    .lighten(0.175)
                    .hex(),
            ]}
            layers={['grid', 'axes', 'bars', 'markers', 'legends']}
            legends={legends}
        />
    );
};

export default MyResponsiveBar;
