import React from 'react';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import TreeSelector from '../components/TreeSelector';

export default [
    {
        name: 'classSelector',
        label: 'Group Selector',
        initialValue: [null],
        component: props => {
            return (
                <Field name="classSelector">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <TreeSelector
                                field={field}
                                form={form}
                                tree={props.settings.tree}
                                title="Select Group"
                                single
                            />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: true,
    },
];
