import React from 'react';
import PropTypes from 'prop-types';
import Renders from './render';

export default class Component extends React.Component {
    static propTypes = {
        item: PropTypes.object,
        location: PropTypes.object,
    };

    render() {
        const { item } = this.props;

        const ItemRender = Renders[item.render.type];

        return <ItemRender item={item} {...this.props} />;
    }
}
