import React from 'react';

import Modal from 'cccisd-modal';

import IconPencil from 'cccisd-icons/pencil7';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

export default function ConsentFormModal({
    row: { 'pawn.pawnId': pawnId, 'pawn.pawnHash': pawnHash },
    loadData,
    renderTrigger = ({ onClick }) => (
        <>
            <button type="button" className="btn btn-xs btn-default" onClick={onClick}>
                <IconPencil spaceRight />
                Contact Log
            </button>
        </>
    ),
}) {
    return (
        <Modal
            renderTrigger={renderTrigger}
            size="80%"
            bodyStyle={{
                overflow: 'scroll',
            }}
            beforeClose={() => loadData()}
        >
            <DeploymentPlayer
                disableLayout
                deploymentHandle="consent"
                pawnId={pawnId}
                pawnHash={pawnHash}
            />
        </Modal>
    );
}
