/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import _round from 'lodash/round';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import measuresQuery from './measures.graphql';
import itemsQuery from './items.graphql';
import Callout from './Callout';
import style from './style.css';
import { Html } from 'cccisd-wysiwyg';

const Component = props => {
    const { show, pageTitle } = props.settings;

    const [measures, measuresLoading] = useQuery({
        query: props.query ? props.query : measuresQuery,
        variables: props.filters,
    });

    const [items, itemsLoading] = useQuery({
        query: props.query ? props.query : itemsQuery,
        variables: props.filters,
    });

    if (measuresLoading || itemsLoading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure({ measures, items });

    const {
        depressionSymptoms,
        overallGroupRating,
        sdqcp,
        avg_grpsat_1,
        avg_grpsat_2,
        avg_grpsat_3,
        avg_grpsat_4,
        avg_grpsat_5,
        avg_grpsat_6,
        avg_grpsat_7,
        avg_grpsat_8,
        avg_grpsat_9,
    } = calloutsData;

    return (
        <>
            {show && (
                <>
                    <h1 className={style.title}>
                        <Html content={pageTitle} />
                    </h1>
                    <div className={style.callouts}>
                        {props.settings.depressionSymptoms.show && (
                            <Callout
                                handle="depressionSymptoms"
                                settings={{
                                    ...props.settings.depressionSymptoms,
                                    colors: props.settings.colors,
                                }}
                                data={depressionSymptoms}
                            />
                        )}
                        {props.settings.overallGroupRating.show && (
                            <Callout
                                handle="overallGroupRating"
                                settings={{
                                    ...props.settings.overallGroupRating,
                                    colors: props.settings.colors,
                                }}
                                data={overallGroupRating}
                            />
                        )}
                        {props.settings.odgm.show && (
                            <Callout
                                handle="odgm"
                                settings={{
                                    ...props.settings.odgm,
                                    colors: props.settings.colors,
                                }}
                                data={sdqcp}
                            />
                        )}
                    </div>
                    <h2 className={style.individualItemScoresHeading}>
                        <Html content={props.settings.itemSectionTitle} />
                    </h2>
                    <div className={style.individualItemScoresWrapper}>
                        <div className={style.item}>
                            <em>I looked forward to coming to the Act & Adapt group:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_1, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>
                                I used the skills I learned in the Act & Adapt group in my life:
                            </em>
                            <hr />
                            <strong>{_round(avg_grpsat_2, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>The Act & Adapt program helped me do better at home:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_3, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>I got to know most of the group members pretty well:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_4, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>The Act & Adapt program helped me do better at school:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_5, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>I got to know myself better because of the Act & Adapt program:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_6, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>The Act & Adapt program was too short to make a difference:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_7, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>I would recommend the Act & Adapt program to others:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_8, 2) || 'N/A'}</strong>
                        </div>
                        <div className={style.item}>
                            <em>The Act & Adapt program took too much of my time:</em>
                            <hr />
                            <strong>{_round(avg_grpsat_9, 2) || 'N/A'}</strong>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { dPre, dPost, cpPre, cpPost, overallRating } =
            (data.measures.roles.learnerList[0] &&
                data.measures.roles.learnerList[0].ancestorGroups.class.childRoles.measures) ||
            {};
        const { avg_grpsat_1, avg_grpsat_2, avg_grpsat_3 } =
            (data.items.roles.learnerList[0] &&
                data.items.roles.learnerList[0].ancestorGroups.class.childRoles.items1to3) ||
            {};
        const { avg_grpsat_4, avg_grpsat_5, avg_grpsat_6 } =
            (data.items.roles.learnerList[0] &&
                data.items.roles.learnerList[0].ancestorGroups.class.childRoles.items4to6) ||
            {};
        const { avg_grpsat_7, avg_grpsat_8, avg_grpsat_9 } =
            (data.items.roles.learnerList[0] &&
                data.items.roles.learnerList[0].ancestorGroups.class.childRoles.items7to9) ||
            {};
        return {
            depressionSymptoms: {
                preScore: {
                    label: 'Pre-Group',
                    value: _round(dPre, 2) || 'N/A',
                },
                postScore: {
                    label: 'Post-Group',
                    value: _round(dPost, 2) || 'N/A',
                },
            },
            overallGroupRating: {
                score: {
                    label: 'Overall Group Rating',
                    value: _round(overallRating, 2) || 'N/A',
                },
            },
            sdqcp: {
                preScore: {
                    label: 'Pre-Group',
                    value: _round(cpPre, 2) || 'N/A',
                },
                postScore: {
                    label: 'Post-Group',
                    value: _round(cpPost, 2) || 'N/A',
                },
            },
            avg_grpsat_1,
            avg_grpsat_2,
            avg_grpsat_3,
            avg_grpsat_4,
            avg_grpsat_5,
            avg_grpsat_6,
            avg_grpsat_7,
            avg_grpsat_8,
            avg_grpsat_9,
        };
    },
};

export default Component;
