import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'calloutsGlobalScore',
    label: 'Average Global Score Callouts',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
