import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const MyResponsiveBar = props => {
    const {
        keys,
        padding = 0.3,
        legends = [
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 10,
                itemWidth: 75,
                itemHeight: 10,
                itemDirection: 'left-to-right',
                symbolSize: 10,
            },
        ],
    } = props.settings;

    return (
        <ResponsiveBar
            data={props.data}
            keys={keys}
            innerPadding={5}
            padding={padding}
            enableLabel={false}
            groupMode="grouped"
            axisLeft={{
                tickValues: [0, 1, 2, 3, 4, 5],
            }}
            gridYValues={[0, 1, 2, 3, 4, 5]}
            colors={['#0C3C60', '#2087C7', '#FFB293']}
            layers={['grid', 'axes', 'bars', 'markers', 'legends']}
            legends={legends}
        />
    );
};

export default MyResponsiveBar;
