import notification from 'cccisd-notification';

export default function setClipboardText(text) {
    // create a tiny invisible text area to programatically copy text
    var id = 'mycustom-clipboard-textarea-hidden-id';
    var existsTextarea = document.getElementById(id);
    if (!existsTextarea) {
        // if text area does not exist
        var textarea = document.createElement('textarea');
        textarea.id = id;
        textarea.style.position = 'fixed';
        textarea.style.top = 0;
        textarea.style.left = 0;
        textarea.style.width = '1px';
        textarea.style.height = '1px';
        textarea.style.padding = 0;
        textarea.style.border = 'none';
        textarea.style.outline = 'none';
        textarea.style.boxShadow = 'none';
        textarea.style.background = 'transparent';
        document.querySelector('body').appendChild(textarea);
        existsTextarea = document.getElementById(id);
    }
    existsTextarea.value = text;
    existsTextarea.select();
    document.execCommand('copy');
    notification('Link copied!');
}
