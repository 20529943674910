import React from 'react';

import Modal from 'cccisd-modal';
import Button from 'cccisd-click-button';
import { Formik, Form, Field, CccisdSelect, CccisdTextarea } from 'cccisd-formik';

import handleSubmit from '../handleSubmit';

function ArchiveForm({ trigger, decision, data, loadData }) {
    return (
        <Modal
            {...{ trigger }}
            title="Archive Referral"
            render={({ closeModal }) => {
                return (
                    <Formik
                        initialValues={{
                            comments: '',
                            commentsIfOther: '',
                        }}
                        onSubmit={values =>
                            handleSubmit({ data, decision, values, closeModal, loadData })
                        }
                    >
                        {({ isSubmitting, submitForm, values }) => (
                            <Form>
                                <section>
                                    <Field
                                        component={CccisdSelect}
                                        name="comments"
                                        label="Reason for Archive:"
                                        options={[
                                            {
                                                label: '-- Please choose a reason --',
                                                value: '',
                                            },
                                            {
                                                label: 'Student declined',
                                                value: 'Student declined',
                                            },
                                            {
                                                label: 'Student absent',
                                                value: 'Student absent',
                                            },
                                            {
                                                label: 'Student transferred',
                                                value: 'Student transferred',
                                            },
                                            {
                                                label: 'Student no longer interested',
                                                value: 'Student no longer interested',
                                            },
                                            {
                                                label: 'Parent declined',
                                                value: 'Parent declined',
                                            },
                                            {
                                                label: 'Parent unable to be reached',
                                                value: 'Parent unable to be reached',
                                            },
                                            {
                                                label: 'Parent never returned consent',
                                                value: 'Parent never returned consent',
                                            },
                                            {
                                                label:
                                                    'Student not a good fit for the group (Provider decision)',
                                                value:
                                                    'Student not a good fit for the group (Provider decision)',
                                            },
                                            {
                                                label: 'Student will get services elsewhere',
                                                value: 'Student will get services elsewhere',
                                            },
                                            { label: 'Other', value: 'Other' },
                                        ]}
                                    />
                                    {values.comments === 'Other' && (
                                        <Field
                                            component={CccisdTextarea}
                                            name="commentsIfOther"
                                            label="Other reason:"
                                        />
                                    )}
                                </section>
                                <section
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '1em',
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() => closeModal()}
                                    >
                                        Cancel
                                    </button>
                                    <Button
                                        title="Submit"
                                        type="submit"
                                        isLoading={isSubmitting}
                                        className="btn btn-primary"
                                        onClick={() => submitForm()}
                                    />
                                </section>
                            </Form>
                        )}
                    </Formik>
                );
            }}
        />
    );
}

export default ArchiveForm;
