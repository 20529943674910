/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import Chart from './Chart';
import summaryChartsQuery from './summaryChartsQuery.graphql';
import style from './style.css';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { show, chart } = props.settings;

    const [data, loading] = useQuery({
        query: props.query ? props.query : summaryChartsQuery,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const {
        moodChartData,
        riskFactorsChartData,
        copingSkillsChartData,
    } = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={style.overallTable}>
                        <Chart
                            data={moodChartData}
                            settings={{
                                ...chart,
                                targetLineValueMedium: 13,
                                targetLineValueHigh: 19,
                                verticalScaleMin: 0,
                                verticalScaleMax: 40,
                                padding: 0.65,
                            }}
                        />
                    </div>
                    <div className={style.overallTable}>
                        {hidden ? (
                            <div />
                        ) : (
                            <Chart
                                data={riskFactorsChartData}
                                settings={{
                                    ...chart,
                                    showLegend: true,
                                    verticalScaleMin: 0,
                                    verticalScaleMax: 4,
                                    keys: ['Student', 'Comparison'],
                                }}
                            />
                        )}
                    </div>
                    <div className={style.overallTable}>
                        {hidden ? (
                            <div />
                        ) : (
                            <Chart
                                data={copingSkillsChartData}
                                settings={{
                                    ...chart,
                                    showLegend: true,
                                    verticalScaleMin: 0,
                                    verticalScaleMax: 4,
                                    keys: ['Student', 'Comparison'],
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { phase1_1 = { devTags: {} }, phase1_2 = { devTags: {} } } =
            data.roles.learnerList[0] || {};

        return {
            moodChartData: [
                {
                    id: 'CDI',
                    value: Number(phase1_1.devTags.CDI_Sum) || 0,
                },
            ],
            riskFactorsChartData: [
                {
                    id: 'Cog Errors',
                    Student: Number(phase1_2.devTags.Risk_CogErr_1_2) || 0,
                    Comparison: 1.7,
                },
                {
                    id: 'Emo React',
                    Student: Number(phase1_2.devTags.Risk_EmoReact_1_2) || 0,
                    Comparison: 1.1,
                },
            ],
            copingSkillsChartData: [
                {
                    id: 'Primary',
                    Student: Number(phase1_2.devTags.CopPrimary_1_2) || 0,
                    Comparison: 3.5,
                },
                {
                    id: 'Secondary',
                    Student: Number(phase1_2.devTags.CopSecondary_1_2) || 0,
                    Comparison: 3.2,
                },
            ],
        };
    },
};

export default Component;
