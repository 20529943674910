import React from 'react';

import Modal from 'cccisd-modal';

import IconAdd from 'cccisd-icons/plus';

import NewReferralPlayer from './NewReferralPlayer';

export default function ReferralCreateModal({ siteId, loadData }) {
    return (
        <Modal
            trigger={
                <button type="button" className="btn btn-primary">
                    <IconAdd spaceRight />
                    <span>New Referral</span>
                </button>
            }
            size="80%"
            bodyStyle={{
                overflow: 'scroll',
            }}
            beforeClose={() => loadData()}
        >
            <NewReferralPlayer siteId={siteId} />
        </Modal>
    );
}
