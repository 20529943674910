import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import style from './style.css';
import classnames from 'classnames';

export default class IconLabel extends React.Component {
    static propTypes = {
        active: PropTypes.bool,
        item: PropTypes.object,
        location: PropTypes.object,
    };

    processUrl = ({ url, history }) => {
        const found = url.match(/(\/:[\w-]+\?)/g);

        if (found) {
            found.forEach(pattern => {
                url = url.replace(pattern, '');
            });
        }
        history.push(url);
    };

    render() {
        const { item, active } = this.props;
        return (
            <li key={item.handle} className={active ? 'active' : ''}>
                <Route
                    render={({ history }) => (
                        <a
                            onClick={this.processUrl.bind(null, { url: item.url, history })}
                            className={style.navLink}
                        >
                            {item.render.params.icon && (
                                <span className={classnames(style.icon, 'icon')}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox={item.render.params.icon.viewBox}
                                    >
                                        <g>
                                            {item.render.params.icon.paths.map(p => (
                                                <path key={p} d={p} />
                                            ))}
                                        </g>
                                    </svg>
                                </span>
                            )}
                            <div
                                className={classnames(
                                    'secondary-nav-text',
                                    style.labelGroup,
                                    item.render.params.label.split(' ').length > 1
                                        ? ''
                                        : style.stretch
                                )}
                            >
                                {item.render.params.label.split(' ').map(label => (
                                    <div className={style.labelPart}>{label}</div>
                                ))}
                            </div>
                        </a>
                    )}
                />
            </li>
        );
    }
}
