/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _round from 'lodash/round';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import Chart from './Chart';
import summaryChartsQuery from './summaryChartsQuery.graphql';
import style from './style.css';
import { Html } from 'cccisd-wysiwyg';

const ChartContainer = props => {
    return (
        <div className={style.chartContainer}>
            <div className={style.title}>
                <Html content={props.title} />
            </div>
            <div className={style.component}>{props.children}</div>
        </div>
    );
};

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { show, chart, mood, anxiety, copingSkills, conductProblems } = props.settings;

    const [data, loading] = useQuery({
        query: props.query ? props.query : summaryChartsQuery,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const {
        moodChartData,
        anxietyChartData,
        copingSkillsChartData,
        conductProblemsChartData,
    } = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div className={style.chart}>
                        {hidden ? (
                            <div />
                        ) : (
                            <ChartContainer title={mood.title}>
                                <Chart
                                    data={moodChartData}
                                    settings={{
                                        ...chart,
                                        showLegend: true,
                                        targetLineValueMedium: 13,
                                        targetLineValueHigh: 19,
                                        verticalScaleMin: 0,
                                        verticalScaleMax: 40,
                                        padding: 0.4,
                                        keys: ['Pre', 'Post'],
                                    }}
                                />
                            </ChartContainer>
                        )}
                    </div>
                    <div className={style.chart}>
                        {hidden ? (
                            <div />
                        ) : (
                            <ChartContainer title={anxiety.title}>
                                <Chart
                                    data={anxietyChartData}
                                    settings={{
                                        ...chart,
                                        showLegend: true,
                                        verticalScaleMin: 0,
                                        verticalScaleMax: 25,
                                        padding: 0.4,
                                        tickValues: 6,
                                        gridYValues: [0, 5, 10, 15, 20, 25],
                                        keys: ['Pre', 'Post'],
                                    }}
                                />
                            </ChartContainer>
                        )}
                    </div>
                    <div className={style.chart}>
                        {hidden ? (
                            <div />
                        ) : (
                            <ChartContainer title={copingSkills.title}>
                                <Chart
                                    data={copingSkillsChartData}
                                    settings={{
                                        ...chart,
                                        showLegend: true,
                                        verticalScaleMin: 0,
                                        verticalScaleMax: 4,
                                        keys: ['Pre', 'Post'],
                                    }}
                                />
                            </ChartContainer>
                        )}
                    </div>
                    <div className={style.chart}>
                        {hidden ? (
                            <div />
                        ) : (
                            <ChartContainer title={conductProblems.title}>
                                <Chart
                                    data={conductProblemsChartData}
                                    settings={{
                                        ...chart,
                                        showLegend: true,
                                        verticalScaleMin: 0,
                                        verticalScaleMax: 10,
                                        padding: 0.4,
                                        keys: ['Pre', 'Post'],
                                    }}
                                />
                            </ChartContainer>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const {
            phase1_1 = { devTags: {} },
            phase1_2 = { devTags: {} },
            phase1_3 = { devTags: {} },
        } = data.roles.learnerList[0] || {};

        return {
            moodChartData: [
                {
                    id: '',
                    Pre: _round(Number(phase1_1.devTags.CDI_Sum), 2) || 0,
                    Post: _round(Number(phase1_3.devTags.CDI_Sum_1_3), 2) || 0,
                },
            ],
            anxietyChartData: [
                {
                    id: '',
                    Pre: _round(Number(phase1_1.devTags.RCADS_AnxietySum), 2) || 0,
                    Post: _round(Number(phase1_3.devTags.RCADS_AnxietySum_1_3), 2) || 0,
                },
            ],
            copingSkillsChartData: [
                {
                    id: 'Primary Control Beliefs',
                    Pre: _round(Number(phase1_2.devTags.CopPrimary_1_2), 2) || 0,
                    Post: _round(Number(phase1_3.devTags.CopPrimary_1_3), 2) || 0,
                },
                {
                    id: 'Secondary Control Beliefs',
                    Pre: _round(Number(phase1_2.devTags.CopSecondary_1_2), 2) || 0,
                    Post: _round(Number(phase1_3.devTags.CopSecondary_1_3), 2) || 0,
                },
            ],
            conductProblemsChartData: [
                {
                    id: '',
                    Pre: _round(Number(phase1_1.devTags.SDQ_CP_Sum), 2) || 0,
                    Post: _round(Number(phase1_3.devTags.SDQ_CP_Sum_1_3), 2) || 0,
                },
            ],
        };
    },
};

export default Component;
