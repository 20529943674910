/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';

const Component = props => {
    const { title, footnotes } = props.settings;

    return (
        <>
            <h1 className={style.title}>
                <Html content={title} />
            </h1>
            <Html content={footnotes} />
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

export default Component;
