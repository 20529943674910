import React from 'react';
import moment from 'moment';

import Tooltip from 'cccisd-tooltip';

import IconInfo from 'cccisd-icons/info';

import TableActions from './components/TableActions';
import ReferralViewModal from './components/ReferralViewModal';
import Phase1Modal from './components/Phase1Modal';
import ConsentFormModal from './components/ConsentFormModal';
import CaregiverConsentModal from './components/CaregiverConsentModal';
import YouthAssentModal from './components/YouthAssentModal';
import PotentialTreatmentGroupModal from './components/PotentialTreatmentGroupModal';
import renderScoreLevel from '../../renders/ScoreLevel';

const Fortress = window.cccisd && window.cccisd.fortress;

export default function useColumns({ phase1DeploymentHash, pendingTableRef, excludedTableRef }) {
    const pending = [
        {
            name: 'screening.devTags.YouthFirstName',
            label: 'Student Name',
            filter: true,
            sort: true,
            render: ({
                row: {
                    'screening.devTags.YouthFirstName': firstName,
                    'screening.devTags.YouthLastName': lastName,
                },
            }) => {
                return `${firstName || ''} ${lastName ? `${lastName.split('')[0]}.` : ''}`;
            },
        },
        {
            name: 'screening.devTags.YouthGrade',
            label: 'Grade',
            filter: true,
            sort: true,
        },
        {
            name: 'screening.devTags.YouthAge',
            label: 'Age',
            filter: true,
            sort: true,
        },
        {
            name: 'screening.devTags.YouthDOB',
            label: 'DOB',
            filter: true,
            filterSettings: {
                type: 'date',
            },
            sort: true,
            render: ({ row: { 'screening.devTags.YouthDOB': dob } }) => {
                return dob ? moment(dob).format('MM/DD/YYYY') : '';
            },
        },
        {
            name: 'screening.devTags.ReferrerTitle',
            label: 'Referring Person',
            filter: true,
            sort: true,
        },
        {
            name: 'screening.completedDate',
            label: 'Referral Date',
            render: ReferralViewModal,
            filter: true,
            filterSettings: {
                type: 'date',
            },
            sort: true,
        },
        {
            name: 'caregiverContact&Consent',
            label: 'Contact Log',
            render: ConsentFormModal,
        },
        {
            name: 'consent.devTags.YouthApproached',
            label: 'Youth Approached?',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                placeholder: '-- Choose one --',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' },
                ],
            },
            render: ({
                row: {
                    'consent.devTags.YouthApproached': youthApproached,
                    'consent.devTags.ReasonYouthNotApproached': reasonYouthNotApproached,
                },
            }) => {
                if (youthApproached) {
                    return youthApproached === '1' ? (
                        <span className="label label-success">Yes</span>
                    ) : (
                        <Tooltip title={reasonYouthNotApproached || 'No information provided.'}>
                            <span className="label label-warning">
                                No <IconInfo />
                            </span>
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
        {
            name: 'consent.devTags.YouthInterested',
            label: 'Youth Interested?',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                placeholder: '-- Choose one --',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' },
                ],
            },
            render: ({
                row: {
                    'consent.devTags.YouthInterested': youthInterested,
                    'consent.devTags.ReasonYouthNotInterested': reasonYouthNotInterested,
                },
            }) => {
                if (youthInterested) {
                    return youthInterested === '1' ? (
                        <span className="label label-success">Yes</span>
                    ) : (
                        <Tooltip title={reasonYouthNotInterested || 'No information provided.'}>
                            <span className="label label-warning">
                                No <IconInfo />
                            </span>
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
        {
            name: 'consent.devTags.CaregiverApproached',
            label: 'Caregiver Approached?',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                placeholder: '-- Choose one --',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' },
                ],
            },
            render: ({
                row: {
                    'consent.devTags.CaregiverApproached': caregiverApproached,
                    'consent.devTags.ReasonCaregiverNotApproached': reasonCaregiverNotApproached,
                },
            }) => {
                if (caregiverApproached) {
                    return caregiverApproached === '1' ? (
                        <span className="label label-success">Yes</span>
                    ) : (
                        <Tooltip title={reasonCaregiverNotApproached || 'No information provided.'}>
                            <span className="label label-warning">
                                No <IconInfo />
                            </span>
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
        {
            name: 'consent.devTags.CaregiverConsentDateSent',
            label: 'Consent Form Sent?',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'date',
            },
            render: ({
                row: {
                    'consent.devTags.CaregiverConsentPacketSent': caregiverConsentPacketSent,
                    'consent.devTags.CaregiverConsentDateSent': caregiverConsentDateSent,
                    'consent.devTags.ReasonCaregiverConsentPacketNotSent': reasonCaregiverConsentPacketNotSent,
                },
            }) => {
                if (caregiverConsentPacketSent) {
                    return caregiverConsentPacketSent === '1' ? (
                        <span className="label label-success">
                            {`Yes - ${
                                caregiverConsentDateSent
                                    ? moment(caregiverConsentDateSent).format('MM/DD/YYYY')
                                    : 'No date provided'
                            }`}
                        </span>
                    ) : (
                        <Tooltip
                            title={
                                reasonCaregiverConsentPacketNotSent || 'No information provided.'
                            }
                        >
                            <span className="label label-warning">
                                No <IconInfo />
                            </span>
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
        {
            name: 'caregiverConsent',
            label: 'Caregiver Consents?',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                placeholder: '-- Choose one --',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' },
                    { label: 'Not yet received', value: 'NA' },
                ],
            },
            render: CaregiverConsentModal,
        },
        {
            name: 'youthAssent',
            label: 'Youth Assents?',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                placeholder: '-- Choose one --',
                options: [
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' },
                    { label: 'Not yet received', value: 'NA' },
                ],
            },
            render: YouthAssentModal,
        },
        Fortress.user.acting.role.handle === 'orgAdmin' && {
            name: 'include',
            label: 'Ready for Referral Assessment',
            render: ({ row, loadData }) => (
                <TableActions
                    row={row}
                    loadData={() => {
                        loadData();
                        excludedTableRef.current.loadData();
                    }}
                    columnType="include"
                />
            ),
        },
        {
            name: 'archive',
            label: 'Archive Youth?',
            render: ({ row, loadData }) => (
                <TableActions
                    row={row}
                    loadData={() => {
                        loadData();
                        excludedTableRef.current.loadData();
                    }}
                    columnType="archive"
                />
            ),
        },
    ].filter(c => c);

    const included = [
        {
            name: 'fields.participantId',
            label: 'A&A ID',
            filter: true,
            sort: true,
        },
        {
            name: 'screening.devTags.YouthFirstName',
            label: 'Student Name',
            filter: true,
            sort: true,
            render: ({
                row: {
                    'screening.devTags.YouthFirstName': firstName,
                    'screening.devTags.YouthLastName': lastName,
                },
            }) => {
                return `${firstName || ''} ${lastName ? `${lastName.split('')[0]}.` : ''}`;
            },
        },
        {
            name: 'screening.devTags.YouthGrade',
            label: 'Grade',
            filter: true,
            sort: true,
        },
        {
            name: '',
            label: 'Screening Forms',
            render: data => {
                return (
                    <div className="btn-group dropup">
                        <button
                            type="button"
                            className="btn btn-xs btn-default dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            View Forms <span className="caret" />
                        </button>
                        <ul className="dropdown-menu">
                            <ReferralViewModal
                                tableType="included"
                                renderTrigger={({ onClick }) => (
                                    <li onClick={onClick}>
                                        <a href="#">Referral</a>
                                    </li>
                                )}
                                {...data}
                            />
                            <ConsentFormModal
                                renderTrigger={({ onClick }) => (
                                    <li onClick={onClick}>
                                        <a href="#">Consent</a>
                                    </li>
                                )}
                                {...data}
                            />
                        </ul>
                    </div>
                );
            },
        },
        {
            name: '1.1 Assessment',
            label: '1.1 Assessment',
            render: ({ row, loadData }) => (
                <Phase1Modal row={row} loadData={loadData} deploymentHash={phase1DeploymentHash} />
            ),
        },
        {
            name: 'phase1_1.devTags.RCADS_DepSum',
            label: 'RCADS Risk Level',
            filter: true,
            sort: true,
            render: ({
                value,
                row,
                row: {
                    'phase1_1.devTags.RCADS_DepSum': RCADSDepSum,
                    'phase1_1.devTags.RCADS_DepRisk_Category': RCADSDepRiskCat,
                },
            }) => {
                if (RCADSDepSum && RCADSDepRiskCat) {
                    return renderScoreLevel({ scoreTag: 'RCADS_DepSum' })({ value, row });
                }
                return '-';
            },
        },
        {
            name: 'phase1_1.devTags.CDI_Sum',
            label: 'CDI Risk Level',
            filter: true,
            sort: true,
            render: ({
                value,
                row,
                row: {
                    'phase1_1.devTags.CDI_Sum': CDISum,
                    'phase1_1.devTags.CDI_Risk_Category': CDIRiskCat,
                },
            }) => {
                if (CDISum && CDIRiskCat) {
                    return renderScoreLevel({ scoreTag: 'CDI_Sum' })({ value, row });
                }
                return '-';
            },
        },
        {
            name: 'Eligibility',
            label: 'Eligibility',
            render: ({
                row: {
                    'phase1_1.devTags.CDI_Risk': CDIRisk,
                    'phase1_1.devTags.RCADS_DepRisk': RCADSDepRisk,
                },
            }) => {
                if (RCADSDepRisk || CDIRisk) {
                    if (Number(RCADSDepRisk) >= 1 || Number(CDIRisk) >= 2) {
                        return <span className="label label-success">Eligible</span>;
                    }
                    if (Number(RCADSDepRisk) < 1 && Number(CDIRisk) < 2) {
                        return <span className="label label-warning">Not Eligible</span>;
                    }
                }
                return <span className="label label-default">TBD</span>;
            },
        },
        {
            name: 'fields.potentialTreatmentGroup',
            label: 'Potential Treatment Group',
            filter: true,
            sort: true,
            render: PotentialTreatmentGroupModal,
        },
    ];

    const excluded = [
        {
            name: 'screening.devTags.YouthFirstName',
            label: 'Student Name',
            filter: true,
            sort: true,
            render: ({
                row: {
                    'screening.devTags.YouthFirstName': firstName,
                    'screening.devTags.YouthLastName': lastName,
                },
            }) => {
                return `${firstName || ''} ${lastName ? `${lastName.split('')[0]}.` : ''}`;
            },
        },
        {
            name: 'screening.devTags.YouthGrade',
            label: 'Grade',
            filter: true,
            sort: true,
        },
        {
            name: 'screening.devTags.YouthAge',
            label: 'Age',
            filter: true,
            sort: true,
        },
        {
            name: 'screening.devTags.YouthDOB',
            label: 'DOB',
            filter: true,
            filterSettings: {
                type: 'date',
            },
            sort: true,
            render: ({ row: { 'screening.devTags.YouthDOB': dob } }) => {
                return dob ? moment(dob).format('MM/DD/YYYY') : '';
            },
        },
        {
            name: 'screening.devTags.ReferrerTitle',
            label: 'Referring Person',
            filter: true,
            sort: true,
        },
        {
            name: 'screening.completedDate',
            label: 'Referral Date',
            render: ({ row, loadData }) => {
                return <ReferralViewModal row={row} loadData={loadData} tableType="excluded" />;
            },
            filter: true,
            filterSettings: {
                type: 'date',
            },
            sort: true,
        },
        {
            name: 'screening.devTags.ScreeningComments',
            label: 'Reason for Archive',
            filter: true,
            sort: true,
        },
        {
            name: 'actions',
            label: 'Actions',
            render: ({ row, loadData }) => (
                <TableActions
                    row={row}
                    loadData={() => {
                        loadData();
                        pendingTableRef.current.loadData();
                    }}
                    tableType="excluded"
                />
            ),
        },
    ];

    return {
        pending,
        included,
        excluded,
    };
}
