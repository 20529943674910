import React from 'react';
import PropTypes from 'prop-types';
import { Field, CccisdWysiwyg, CccisdInput } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import ColorPicker from '../../components/ColorPicker';
import filterFields from '../filterFields.js';
import widgets from '../widgets/index.js';

export const initialValues = {
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    colors: { primary: '#3673b6' },
    header: {
        title: 'Act & Adapt Program Outcomes Profile',
        subtitle: 'SCHOOL',
        learnerText: 'Student Name:',
        referralInterviewDateText: 'Referral Interview:',
        preTreatmentInterviewDateText: 'Pre-Treatment Interview:',
        postTreatmentInterviewDateText: 'Post-Treatment Interview:',
        createdDateText: 'Report Created On:',
        description:
            'This report includes changes in symptoms and coping skills before and after Act & Adapt, along with indicators of program satisfaction.',
        gradeText: 'Grade:',
    },
    body: {
        heading: '',
    },
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = props => {
    return (
        <div>
            <BuilderBlock title="Header">
                <Field name="header.title" component={CccisdWysiwyg} label="Title" />
                <Field name="header.subtitle" component={CccisdWysiwyg} label="Subtitle" />
                <Field name="header.description" component={CccisdWysiwyg} label="Description" />
                <Field
                    name="header.referralInterviewDateText"
                    component={CccisdInput}
                    label="Referral Interview Date Text"
                />
                <Field
                    name="header.preTreatmentInterviewDateText"
                    component={CccisdInput}
                    label="Pre-Treatment Interview Date Text"
                />
                <Field
                    name="header.postTreatmentInterviewDateText"
                    component={CccisdInput}
                    label="Post-Treatment Interview Date Text"
                />
                <Field
                    name="header.createdDateText"
                    component={CccisdInput}
                    label="Created Date Text"
                />
                <BuilderBlock title="Subheader">
                    <Field name="header.learnerText" component={CccisdInput} label="Learner Text" />
                    <Field name="header.gradeText" component={CccisdInput} label="Grade Text" />
                </BuilderBlock>
            </BuilderBlock>
            <BuilderBlock title="Body">
                <Field name="body.heading" component={CccisdWysiwyg} label="Page Heading" />
            </BuilderBlock>
            <BuilderBlock title="Color">
                <Field name="colors.primary">
                    {({ field, form }) => {
                        return <ColorPicker field={field} form={form} />;
                    }}
                </Field>
            </BuilderBlock>
        </div>
    );
};

Component.propTypes = {
    onValidChange: PropTypes.func,
    settings: PropTypes.object,
};

export default reportTemplateBuilder({ filterFields, widgets })(Component);
