import React from 'react';
import _ from 'lodash';

import { Form as AppDefsForm } from 'cccisd-laravel-appdefs';
import { RosteringButtons } from 'cccisd-laravel-nexus';
import Tooltip from 'cccisd-tooltip';

import IconArchive from 'cccisd-icons/box-add';
import IconUnarchive from 'cccisd-icons/box-remove';

import handleSubmit from '../handleSubmit.js';
import ArchiveForm from './ArchiveForm';

const AppDefs = window.cccisd && window.cccisd.appDefs;
const AppDefRoutes = _.cloneDeep(AppDefs.routes);

export default function TableActions({ row, loadData, tableType = 'pending', columnType = '' }) {
    let { form, type, routes } = AppDefRoutes.find(
        r => r.handle === 'manage'
    ).componentProps.verticalNav.find(n => n.handle === 'learner').contentConfig;

    form.fields = form.fields
        .map(f => {
            if (f.handle === 'studentOrgId') {
                f.graphql.edit.valuePath = 'roles.learner.assignmentProgress.devTags.StudentOrgId';
                return f;
            }
            if (f.handle === 'first_name') {
                f.graphql.edit.valuePath =
                    'roles.learner.assignmentProgress.devTags.YouthFirstName';
                return f;
            }
            if (f.handle === 'last_name') {
                f.graphql.edit.valuePath = 'roles.learner.assignmentProgress.devTags.YouthLastName';
                return f;
            }
            if (f.handle === 'grade') {
                f.graphql.edit.valuePath = 'roles.learner.assignmentProgress.devTags.YouthGrade';
                return f;
            }
            if (f.handle === 'initialReferralDate') {
                f.graphql.edit.valuePath = 'roles.learner.assignmentProgress.completedAt';
                return f;
            }
            return f;
        })
        .filter(f => f.handle !== 'class');

    let EditButton = RosteringButtons({
        buttons: [
            {
                id: 1,
                type: 'edit',
                label: '',
                withTooltip: true,
                tooltipText: 'Include Youth for Assessment',
                className: 'btn btn-xs btn-success btn-block',
                icon: {
                    name: 'plus2',
                    paths: [
                        'M992 384h-352v-352c0-17.672-14.328-32-32-32h-192c-17.672 0-32 14.328-32 32v352h-352c-17.672 0-32 14.328-32 32v192c0 17.672 14.328 32 32 32h352v352c0 17.672 14.328 32 32 32h192c17.672 0 32-14.328 32-32v-352h352c17.672 0 32-14.328 32-32v-192c0-17.672-14.328-32-32-32z',
                    ],
                    viewBox: '0 0 1024 1024',
                },
            },
        ],
        FormComponent: AppDefsForm,
        formProps: {
            form,
            type,
            routes,
            afterFormSubmit: async () => {
                await handleSubmit({
                    data: row,
                    decision: 'include',
                    values: {},
                    loadData,
                });
            },
        },
    });

    return (
        <div>
            {tableType === 'excluded' ? (
                <Tooltip title="Unarchive">
                    <button
                        type="button"
                        className="btn btn-xs btn-primary btn-block"
                        onClick={() => handleSubmit({ data: row, decision: 'unexclude', loadData })}
                    >
                        <IconUnarchive />
                    </button>
                </Tooltip>
            ) : (
                <>
                    {columnType === 'include' && <EditButton row={row} loadData={loadData} />}
                    {columnType === 'archive' && (
                        <ArchiveForm
                            decision="exclude"
                            data={row}
                            trigger={
                                <Tooltip title="Archive">
                                    <button
                                        type="button"
                                        className="btn btn-xs btn-primary btn-block"
                                    >
                                        <IconArchive />
                                    </button>
                                </Tooltip>
                            }
                            loadData={loadData}
                        />
                    )}
                </>
            )}
        </div>
    );
}
