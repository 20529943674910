import React from 'react';
import Color from 'color';
import moment from 'moment';
import IconChart from 'cccisd-icons/chart';
import style from './style.css';

const ReportHeader = props => {
    const {
        header: {
            title,
            subtitle,
            groupText,
            groupMemberCountText,
            createdDateText,
            description,
            providersText,
        },
        colors,
    } = props.settings;

    const selectedClass = props.data.groups.classList.find(
        g =>
            String(g.childRoles.metricspawn.pawn.pawnId) ===
            (props.filters.classSelector ? props.filters.classSelector[0] : null)
    );

    return (
        <>
            <div className={style.header}>
                <div className={style.headerTop}>
                    <div
                        className={style.topLeft}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div className={style.headerTitle}>{title}</div>
                    </div>
                    <div
                        className={style.topCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.topCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).lighten(0.1),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div className={style.topRight} style={colors ? { color: colors.primary } : {}}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{createdDateText} </span>
                            {moment(new Date()).format('MMMM Do YYYY')}
                        </div>
                    </div>
                </div>
                <div className={style.headerBot}>
                    <div className={style.botLeft}>
                        <div
                            className={style.description}
                            style={colors ? { color: colors.primary } : {}}
                        >
                            {description}
                        </div>
                    </div>
                    <div
                        className={style.botCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.botCenterShadow}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.1),
                                      }
                                    : {}
                            }
                        />
                        <div
                            className={style.botCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.05),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div
                        className={style.botRight}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{subtitle}</div>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                            {selectedClass && selectedClass.ancestorGroups.site.group.label}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={style.subheader}
                style={
                    colors
                        ? {
                              color: colors.primary,
                          }
                        : {}
                }
            >
                <div className={style.subheaderText}>
                    <div>
                        <span
                            style={
                                props.individuals
                                    ? { fontWeight: 'bold', fontSize: '1.4em' }
                                    : { fontWeight: 'bold' }
                            }
                        >
                            {groupText}{' '}
                        </span>
                        <span style={props.individuals ? { fontSize: '1.4em' } : {}}>
                            {selectedClass && selectedClass.group.label}
                        </span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{providersText} </span>
                        {selectedClass &&
                            selectedClass.targetedByPawns.instructorList.map(
                                (p, i, a) =>
                                    `${p.user.firstName} ${p.user.lastName}${
                                        a.length - 1 !== i ? ', ' : ''
                                    }`
                            )}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{groupMemberCountText} </span>
                        {selectedClass && selectedClass.childRoles.learnerCount}
                    </div>
                </div>
                <div
                    className={style.subheaderIcon}
                    style={props.individuals ? { fontSize: '2em' } : {}}
                >
                    <IconChart />
                </div>
            </div>
        </>
    );
};

export default ReportHeader;
