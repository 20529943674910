import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const MyResponsiveBar = props => {
    const {
        keys = undefined,
        margin = {
            top: 10,
            right: 20,
            bottom: 50,
            left: 40,
        },
        padding = 0.1,
        targetLineValueMedium,
        targetLineValueHigh,
        verticalScaleMin,
        verticalScaleMax,
        tickValues = undefined,
        gridYValues = undefined,
        scoreSuffix,
        xLabel,
        yLabel,
        showLegend,
        legends = [
            {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 20,
                translateY: 45,
                itemsSpacing: 50,
                itemWidth: 75,
                itemHeight: 10,
                itemDirection: 'left-to-right',
                symbolSize: 10,
            },
        ],
    } = props.settings;

    return (
        <ResponsiveBar
            data={props.data}
            keys={keys}
            margin={margin}
            innerPadding={5}
            padding={padding}
            groupMode="grouped"
            minValue={Number(verticalScaleMin)}
            maxValue={Number(verticalScaleMax)}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: xLabel,
                legendOffset: 36,
                legendPosition: 'middle',
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLabel,
                legendOffset: -32,
                legendPosition: 'middle',
                format: n => `${n}${scoreSuffix}`,
                tickValues,
            }}
            gridYValues={gridYValues}
            markers={
                targetLineValueMedium && targetLineValueHigh
                    ? [
                          {
                              axis: 'y',
                              value: Number(targetLineValueMedium),
                              lineStyle: {
                                  stroke: 'orange',
                                  strokeWidth: 2,
                                  strokeDasharray: '10,5',
                              },
                              legend: 'Medium',
                              legendOrientation: 'horizontal',
                          },
                          {
                              axis: 'y',
                              value: Number(targetLineValueHigh),
                              lineStyle: {
                                  stroke: 'red',
                                  strokeWidth: 2,
                              },
                              legend: 'High',
                              legendOrientation: 'horizontal',
                          },
                      ]
                    : []
            }
            colors={{ scheme: 'category10' }}
            layers={
                showLegend
                    ? ['grid', 'axes', 'bars', 'markers', 'legends']
                    : ['grid', 'axes', 'bars', 'markers']
            }
            legends={legends}
        />
    );
};

export default MyResponsiveBar;
