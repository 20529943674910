import React from 'react';
import { Field, CccisdToggle, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    chart: {
        xLabel: '',
        yLabel: 'Score',
        showLegend: false,
        targetLineValue: '',
        verticalScaleMin: '0',
        verticalScaleMax: '80',
        scoreSuffix: '',
    },
    mood: {
        show: true,
        title: 'Depression Symptoms (CDI)',
    },
    anxiety: {
        show: true,
        title: 'Anxiety Symptoms (RCADS)',
    },
    copingSkills: {
        show: true,
        title: 'Coping Skills',
    },
    conductProblems: {
        show: true,
        title: 'Conduct Problems (SDQ)',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Charts?" />
            <h4>Mood</h4>
            <Field name="mood.show" component={CccisdToggle} label="Show Mood?" />
            <Field name="mood.title" component={CccisdWysiwyg} label="Title" />
            <h4>Anxiety</h4>
            <Field name="anxiety.show" component={CccisdToggle} label="Show Anxiety?" />
            <Field name="anxiety.title" component={CccisdWysiwyg} label="Title" />
            <h4>Coping Skills</h4>
            <Field name="copingSkills.show" component={CccisdToggle} label="Show Coping Skills?" />
            <Field name="copingSkills.title" component={CccisdWysiwyg} label="Title" />
            <h4>Conduct Problems</h4>
            <Field
                name="conductProblems.show"
                component={CccisdToggle}
                label="Show Conduct Problems?"
            />
            <Field name="conductProblems.title" component={CccisdWysiwyg} label="Title" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
