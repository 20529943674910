import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { SessionDataEntry } from 'cccisd-laravel-assignment';

import classListQuery from './classList.graphql';
import { getPermissions, getPermissionFilter } from './permissions';

var Fortress = window.cccisd.fortress;

function SessionData() {
    const [classList, setClassList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const data = await getClassList();
        const formattedClassList = formatClassList(data.groups.classList);

        await setClassList(formattedClassList);

        setIsLoading(false);
    }

    async function getClassList() {
        let groupId = Fortress.user.acting.group_id;
        let pawnId = Fortress.user.acting.id;
        const res = await apollo.query({
            query: classListQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
                groupId,
                permissions: getPermissions(),
                permissionFilter: getPermissionFilter(),
            },
        });

        return res.data;
    }

    function formatClassList(list) {
        return list.map(
            ({
                group: { groupId, label },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                    learnerList,
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: getFlowProps(learnerList, label),
                };
            }
        );
    }

    function formatLearnerList(list) {
        return list.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.fields.firstName
                    ? `${item.fields.firstName} ${item.fields.lastName &&
                          `${item.fields.lastName.split('')[0]}.`}`
                    : item.fields.participantId || '',
            };
        });
    }

    function getFlowProps(learnerList, group) {
        return {
            userLists: {
                participants_list: formatLearnerList(learnerList),
            },
            variables: {
                group,
                provider: Fortress.user.acting.user.full_name,
            },
        };
    }

    return (
        !isLoading && (
            <SessionDataEntry
                questprojectIds={[1]}
                groups={classList}
                description="Session Data supports data entry and collection at the session and client-level. Users may enter contact data (attendance), adherence and fidelity data for each session. To explore this data further, go to the Report Center to view corresponding reports."
            />
        )
    );
}

export default SessionData;
