import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    provider1Score: {
        show: true,
        title: 'Provider 1',
        description: 'Avg. Global Score',
    },
    provider2Score: {
        show: true,
        title: 'Provider 2',
        description: 'Avg. Global Score',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <h4>Callout 1</h4>
            <Field name="provider1Score.show" component={CccisdToggle} label="Show Callout 1?" />
            <Field name="provider1Score.title" component={CccisdInput} label="Title" />
            <Field name="provider1Score.description" component={CccisdInput} label="Description" />
            <h4>Callout 1</h4>
            <Field name="provider2Score.show" component={CccisdToggle} label="Show Callout 1?" />
            <Field name="provider2Score.title" component={CccisdInput} label="Title" />
            <Field name="provider2Score.description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
