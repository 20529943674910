import React from 'react';

import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

import IconPencil from 'cccisd-icons/pencil7';
import IconClipboard from 'cccisd-icons/copy';

import setClipboardText from '../setClipboardText';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default function Phase1Modal({
    deploymentHash,
    row: {
        'pawn.pawnId': pawnId,
        'pawn.pawnHash': pawnHash,
        'pawn.respondentHash': respondentHash,
        'fields.participantId': participantId,
    },
    loadData,
}) {
    return (
        <>
            <Modal
                trigger={
                    <button type="button" className="btn btn-xs btn-primary">
                        <IconPencil />
                    </button>
                }
                size="80%"
                bodyStyle={{
                    overflow: 'scroll',
                }}
                beforeClose={() => loadData()}
            >
                <DeploymentPlayer
                    disableLayout
                    deploymentHandle="phase_1: child referral interview_qyidt0o"
                    pawnId={pawnId}
                    pawnHash={pawnHash}
                    flowProps={{
                        variables: {
                            'fields.participantId': participantId,
                        },
                    }}
                    mediatorProps={{
                        enablePrintMode: true,
                    }}
                />
            </Modal>
            <Tooltip title="Copy Link">
                <button
                    type="button"
                    className="btn btn-xs btn-default"
                    style={{ margin: '0 0 0 0.5rem' }}
                    onClick={() =>
                        setClipboardText(Boilerplate.url(`d/${deploymentHash}/${respondentHash}`))
                    }
                >
                    <IconClipboard />
                </button>
            </Tooltip>
        </>
    );
}
