import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import color from 'color';

const MyResponsiveBar = props => {
    const {
        keys,
        padding = 0.3,
        verticalScaleMin,
        verticalScaleMax,
        legends = [
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 10,
                itemWidth: 75,
                itemHeight: 10,
                itemDirection: 'left-to-right',
                symbolSize: 10,
            },
        ],
    } = props.settings;

    return (
        <ResponsiveBar
            data={props.data}
            keys={keys}
            innerPadding={5}
            padding={padding}
            enableLabel={false}
            groupMode="grouped"
            minValue={Number(verticalScaleMin)}
            maxValue={Number(verticalScaleMax)}
            axisLeft={{
                tickValues: [0, 1, 2, 3],
            }}
            gridYValues={[0, 1, 2, 3]}
            colors={[
                '#0C3C60',
                '#2087C7',
                '#FFB293',
                '#AFCBE0',
                color('#FFB293')
                    .lighten(0.175)
                    .hex(),
                color('#AFCBE0')
                    .lighten(0.175)
                    .hex(),
            ]}
            layers={['grid', 'axes', 'bars', 'markers', 'legends']}
            legends={legends}
        />
    );
};

export default MyResponsiveBar;
