/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import Chart from './Chart';
import chartsQuery from '../../class.graphql';
import style from './style.css';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { show, chart } = props.settings;
    const { query } = props;

    const [data, loading] = useQuery({
        query: query || chartsQuery,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const { grpSatData = [], prgSatData = [] } = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>Group Satisfaction</h2>
                                    <Chart
                                        data={grpSatData}
                                        settings={{
                                            ...chart,
                                            keys:
                                                data &&
                                                data.flows.assignmentProgressList.map(
                                                    ap => ap.provider.ProviderFullName
                                                ),
                                            tickValues: [0, 1, 2, 3, 4, 5],
                                            margin: {
                                                left: 425,
                                                bottom: 100,
                                                right: 50,
                                            },
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>Program Satisfaction</h2>
                                    <Chart
                                        data={prgSatData}
                                        settings={{
                                            ...chart,
                                            keys:
                                                data &&
                                                data.flows.assignmentProgressList.map(
                                                    ap => ap.provider.ProviderFullName
                                                ),
                                            tickValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                                            margin: {
                                                left: 250,
                                                bottom: 100,
                                                right: 50,
                                            },
                                            legends: [
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: -100,
                                                    translateY: 60,
                                                    itemsSpacing: 80,
                                                    itemWidth: 75,
                                                    itemHeight: 10,
                                                    itemDirection: 'left-to-right',
                                                    symbolSize: 10,
                                                },
                                            ],
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { assignmentProgressList, flow } = data ? data.flows : {};

        function getGrpSatChartData(apArr) {
            const items = flow.grpSat.content.rows.map(r => r.label.replace(/&amp;/g, '&'));

            return items
                .map((item, i) => {
                    return {
                        id: item.length > 75 ? item.slice(0, 75).concat('...') : item,
                        ...apArr.reduce((a, c) => {
                            return {
                                ...a,
                                [c.provider.ProviderFullName]: Number(c.grpSat[`GrpSat_Q${i + 1}`]),
                            };
                        }, {}),
                    };
                })
                .reverse();
        }

        function getPrgSatChartData(apArr) {
            const items = flow.prgSat.content.rows.map(r => r.label.replace(/&amp;/g, '&'));

            return items
                .map((item, i) => {
                    return {
                        id: item,
                        ...apArr.reduce((a, c) => {
                            return {
                                ...a,
                                [c.provider.ProviderFullName]: Number(c.prgSat[`PrgSat_Q${i + 1}`]),
                            };
                        }, {}),
                    };
                })
                .reverse();
        }

        return {
            grpSatData:
                assignmentProgressList.length > 0 ? getGrpSatChartData(assignmentProgressList) : [],
            prgSatData:
                assignmentProgressList.length > 0 ? getPrgSatChartData(assignmentProgressList) : [],
        };
    },
};

export default Component;
