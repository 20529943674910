import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import calloutsQuery from '../../class.graphql';
import Callout from './Callout';
import _round from 'lodash/round';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;
    const { query } = props;

    const [data, loading] = useQuery({
        query: query || calloutsQuery,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const { groupSatisfaction = 0, programSatisfaction = 0 } = calloutsData;

    return (
        <>
            {show && (
                <>
                    <h1 className={style.title}>Group & Program Satisfaction</h1>
                    <div className={style.callouts}>
                        {props.settings.groupSatisfaction.show && (
                            <Callout
                                handle="groupSatisfaction"
                                settings={{
                                    ...props.settings.groupSatisfaction,
                                    colors: props.settings.colors,
                                }}
                                data={{
                                    value: groupSatisfaction,
                                }}
                            />
                        )}
                        {props.settings.programSatisfaction.show && (
                            <Callout
                                handle="programSatisfaction"
                                settings={{
                                    ...props.settings.programSatisfaction,
                                    colors: props.settings.colors,
                                }}
                                data={{
                                    value: programSatisfaction,
                                }}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { assignmentProgressList } = data ? data.flows : {};

        function getAvg(arr, numOfQs) {
            if (arr.length === 0) {
                return 0;
            }
            const numerator = arr.reduce((a, c) => {
                return a + Number(c) / numOfQs;
            }, 0);

            const denominator = arr.length;

            return _round(numerator / denominator, 1);
        }

        return {
            groupSatisfaction: getAvg(
                assignmentProgressList.map(ap => ap.satsums.Sum_GroupSatisfaction),
                9
            ),
            programSatisfaction: getAvg(
                assignmentProgressList.map(ap => ap.satsums.Sum_ProgramSatisfaction),
                7
            ),
        };
    },
};

export default Component;
