import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Color from 'color';

import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';

import useQuery from '../../../../useQuery';
import classQuery from '../../class.graphql';
import style from './style.css';

const Component = props => {
    const { show, title, description, colors } = props.settings;

    const [data, loading] = useQuery({
        query: classQuery,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const { sessionRows, groupMembers, subtotals, totals } = props.convertDataStructure(data);

    const shade = colors ? Color(colors.primary).alpha(0.1) : 'lightgrey';

    return (
        <>
            {show && (
                <div className={style.container}>
                    <div className={style.grid}>
                        <div
                            className={`${style.title} ${style.cell}`}
                            style={{ backgroundColor: shade }}
                        >
                            {title}
                        </div>

                        <div
                            className={`${style.groupMember} ${style.cell}`}
                            style={{ gridColumn: '1 / 4' }}
                        >
                            Group Members:
                        </div>
                        {groupMembers.map((d, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`${style.groupMember} ${style.cell}`}
                                    style={{
                                        gridColumn: `${4 * (i + 1)} / ${4 * (i + 1) + 4}`,
                                        backgroundColor: i % 2 === 0 && shade,
                                    }}
                                >
                                    {d && d.fields.firstName}
                                </div>
                            );
                        })}

                        <div
                            className={`${style.head} ${style.cell}`}
                            style={{ gridColumn: '1 / 2' }}
                        >
                            <div>Session #</div>
                        </div>
                        <div
                            className={`${style.head} ${style.cell}`}
                            style={{ gridColumn: '2 / 4' }}
                        >
                            <div>Date</div>
                        </div>
                        {groupMembers.map((d, i) => {
                            const heads = ['Attendance', 'Book/PDF', 'Assignment', 'Extra Credit'];

                            return heads.map((h, j) => {
                                return (
                                    <div
                                        key={`${i}-${j}`}
                                        className={`${style.head} ${style.cell}`}
                                        style={{
                                            gridColumn: `${4 * (i + 1) + j} / ${4 * (i + 1) +
                                                1 +
                                                j}`,
                                        }}
                                    >
                                        <div>{h}</div>
                                    </div>
                                );
                            });
                        })}

                        {sessionRows.map((d, i) => {
                            const cells = Array(26)
                                .fill(null)
                                .map((_, j) => {
                                    if (!d) {
                                        return null;
                                    }
                                    if (j === 0) {
                                        return d.deployment.timepoint;
                                    }
                                    if (j === 1) {
                                        return (
                                            d.devTags.SessionDate &&
                                            moment(d.devTags.SessionDate).format('M/D/Y')
                                        );
                                    }

                                    // Attendance Pts
                                    if (j === 2) {
                                        return (
                                            (d.learners[0] && d.learners[0].YouthAttendance) || null
                                        );
                                    }
                                    if (j === 6) {
                                        return (
                                            (d.learners[1] && d.learners[1].YouthAttendance) || null
                                        );
                                    }
                                    if (j === 10) {
                                        return (
                                            (d.learners[2] && d.learners[2].YouthAttendance) || null
                                        );
                                    }
                                    if (j === 14) {
                                        return (
                                            (d.learners[3] && d.learners[3].YouthAttendance) || null
                                        );
                                    }
                                    if (j === 18) {
                                        return (
                                            (d.learners[4] && d.learners[4].YouthAttendance) || null
                                        );
                                    }
                                    if (j === 22) {
                                        return (
                                            (d.learners[5] && d.learners[5].YouthAttendance) || null
                                        );
                                    }

                                    // Practice Book Pts
                                    if (j === 3) {
                                        return (
                                            (d.learners[0] &&
                                                d.learners[0].devTags &&
                                                d.learners[0].devTags.PracticeBookPts) ||
                                            null
                                        );
                                    }
                                    if (j === 7) {
                                        return (
                                            (d.learners[1] &&
                                                d.learners[1].devTags &&
                                                d.learners[1].devTags.PracticeBookPts) ||
                                            null
                                        );
                                    }
                                    if (j === 11) {
                                        return (
                                            (d.learners[2] &&
                                                d.learners[2].devTags &&
                                                d.learners[2].devTags.PracticeBookPts) ||
                                            null
                                        );
                                    }
                                    if (j === 15) {
                                        return (
                                            (d.learners[3] &&
                                                d.learners[3].devTags &&
                                                d.learners[3].devTags.PracticeBookPts) ||
                                            null
                                        );
                                    }
                                    if (j === 19) {
                                        return (
                                            (d.learners[4] &&
                                                d.learners[4].devTags &&
                                                d.learners[4].devTags.PracticeBookPts) ||
                                            null
                                        );
                                    }
                                    if (j === 23) {
                                        return (
                                            (d.learners[5] &&
                                                d.learners[5].devTags &&
                                                d.learners[5].devTags.PracticeBookPts) ||
                                            null
                                        );
                                    }

                                    // Practice Assignment Pts
                                    if (j === 4) {
                                        return (
                                            (d.learners[0] &&
                                                d.learners[0].devTags &&
                                                d.learners[0].devTags.PracticeAssignmentPts) ||
                                            null
                                        );
                                    }
                                    if (j === 8) {
                                        return (
                                            (d.learners[1] &&
                                                d.learners[1].devTags &&
                                                d.learners[1].devTags.PracticeAssignmentPts) ||
                                            null
                                        );
                                    }
                                    if (j === 12) {
                                        return (
                                            (d.learners[2] &&
                                                d.learners[2].devTags &&
                                                d.learners[2].devTags.PracticeAssignmentPts) ||
                                            null
                                        );
                                    }
                                    if (j === 16) {
                                        return (
                                            (d.learners[3] &&
                                                d.learners[3].devTags &&
                                                d.learners[3].devTags.PracticeAssignmentPts) ||
                                            null
                                        );
                                    }
                                    if (j === 20) {
                                        return (
                                            (d.learners[4] &&
                                                d.learners[4].devTags &&
                                                d.learners[4].devTags.PracticeAssignmentPts) ||
                                            null
                                        );
                                    }
                                    if (j === 24) {
                                        return (
                                            (d.learners[5] &&
                                                d.learners[5].devTags &&
                                                d.learners[5].devTags.PracticeAssignmentPts) ||
                                            null
                                        );
                                    }

                                    // Extra Credit Pts

                                    if (j === 5) {
                                        return (
                                            (d.learners[0] &&
                                                d.learners[0].devTags &&
                                                d.learners[0].devTags.ExtraCreditPts) ||
                                            null
                                        );
                                    }
                                    if (j === 9) {
                                        return (
                                            (d.learners[1] &&
                                                d.learners[1].devTags &&
                                                d.learners[1].devTags.ExtraCreditPts) ||
                                            null
                                        );
                                    }
                                    if (j === 13) {
                                        return (
                                            (d.learners[2] &&
                                                d.learners[2].devTags &&
                                                d.learners[2].devTags.ExtraCreditPts) ||
                                            null
                                        );
                                    }
                                    if (j === 17) {
                                        return (
                                            (d.learners[3] &&
                                                d.learners[3].devTags &&
                                                d.learners[3].devTags.ExtraCreditPts) ||
                                            null
                                        );
                                    }
                                    if (j === 21) {
                                        return (
                                            (d.learners[4] &&
                                                d.learners[4].devTags &&
                                                d.learners[4].devTags.ExtraCreditPts) ||
                                            null
                                        );
                                    }
                                    if (j === 25) {
                                        return (
                                            (d.learners[5] &&
                                                d.learners[5].devTags &&
                                                d.learners[5].devTags.ExtraCreditPts) ||
                                            null
                                        );
                                    }

                                    return null;
                                });
                            return cells.map((c, j) => {
                                let gridColumn = `${j + 2} / ${j + 3}`;
                                if (j === 0) {
                                    gridColumn = '1 / 2';
                                }
                                if (j === 1) {
                                    gridColumn = '2 / 4';
                                }

                                return (
                                    <div
                                        className={style.cell}
                                        style={{
                                            gridRow: `${7 + i} / ${7 + i + 1}`,
                                            gridColumn,
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid black',
                                            borderTop:
                                                i === 0 ? '1px solid black' : '1px solid darkgrey',
                                            borderRight:
                                                // eslint-disable-next-line no-nested-ternary
                                                j === 0 ||
                                                j === 1 ||
                                                j === 5 ||
                                                j === 9 ||
                                                j === 13 ||
                                                j === 17 ||
                                                j === 21 ||
                                                j === 25
                                                    ? '1px solid black'
                                                    : '1px solid darkgrey',
                                            borderBottom:
                                                i === sessionRows.length - 1
                                                    ? '1px solid black'
                                                    : '1px solid darkgrey',
                                            borderLeft:
                                                // eslint-disable-next-line no-nested-ternary
                                                j === 0 ||
                                                j === 1 ||
                                                j === 2 ||
                                                j === 6 ||
                                                j === 10 ||
                                                j === 14 ||
                                                j === 18 ||
                                                j === 22
                                                    ? '1px solid black'
                                                    : '1px solid darkgrey',
                                            backgroundColor: i % 2 === 0 && shade,
                                            fontSize: '0.8em',
                                        }}
                                    >
                                        {c || <div />}
                                    </div>
                                );
                            });
                        })}

                        <div
                            className={style.cell}
                            style={{
                                gridRow: `17 / 18`,
                                gridColumn: `1 / 4`,
                                width: '100%',
                                height: '100%',
                                border: '1px solid black',
                            }}
                        >
                            Subtotal
                        </div>
                        {subtotals.map((d, i) => {
                            return (
                                <div
                                    className={style.cell}
                                    style={{
                                        gridRow: `17 / 18`,
                                        gridColumn: `${4 + i} / ${4 + i + 1}`,
                                        width: '100%',
                                        height: '100%',
                                        border: '1px solid black',
                                        borderRight:
                                            i === 3 ||
                                            i === 7 ||
                                            i === 11 ||
                                            i === 15 ||
                                            i === 19 ||
                                            i === 23
                                                ? '1px solid black'
                                                : '1px solid darkgrey',
                                        borderLeft:
                                            i === 0 ||
                                            i === 4 ||
                                            i === 8 ||
                                            i === 12 ||
                                            i === 16 ||
                                            i === 20
                                                ? '1px solid black'
                                                : '1px solid darkgrey',
                                    }}
                                >
                                    {d}
                                </div>
                            );
                        })}

                        <div
                            className={style.cell}
                            style={{
                                gridRow: `18 / 19`,
                                gridColumn: `1 / 4`,
                                width: '100%',
                                height: '100%',
                                border: '1px solid black',
                            }}
                        >
                            Total
                        </div>
                        {totals.map((d, i) => {
                            return (
                                <div
                                    className={style.cell}
                                    style={{
                                        gridRow: `18 / 19`,
                                        gridColumn: `${4 * (i + 1)} / ${4 * (i + 1) + 4}`,
                                        width: '100%',
                                        height: '100%',
                                        border: '1px solid black',
                                    }}
                                >
                                    {d}
                                </div>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            fontSize: '0.5em',
                            backgroundColor: shade,
                            padding: '2em',
                            border: '1px solid black',
                        }}
                    >
                        <Html content={description} />
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { classList } = data ? data.groups : {};
        const group = classList.length > 0 && classList[0].childRoles.metricspawn;
        const learners = classList.length > 0 && classList[0].childRoles.learnerList;
        const sessions =
            group &&
            group.assignmentProgressList
                .map(s => {
                    return {
                        ...s,
                        learners: learners.map(l => {
                            return {
                                ...l.assignmentProgressList.find(
                                    ap => ap.deployment.deploymentId === s.deployment.deploymentId
                                ),
                            };
                        }),
                    };
                })
                .sort((a, b) => Number(a.deployment.timepoint) - Number(b.deployment.timepoint));

        const groupMembersInit = Array(6).fill(null);
        const groupMembers = groupMembersInit.map((g, i) => {
            return learners[i];
        });

        const sessionRowsInit = Array(10).fill(null);
        const sessionRows = sessionRowsInit.map((s, i) => {
            return sessions[i];
        });

        const subtotalsInit = Array(24).fill(null);
        const subtotals = subtotalsInit.map((_, j) => {
            // Attendance Pts
            if (j === 0) {
                return learners[0] && learners[0].subtotals.AttendanceSum;
            }
            if (j === 4) {
                return learners[1] && learners[1].subtotals.AttendanceSum;
            }
            if (j === 8) {
                return learners[2] && learners[2].subtotals.AttendanceSum;
            }
            if (j === 12) {
                return learners[3] && learners[3].subtotals.AttendanceSum;
            }
            if (j === 16) {
                return learners[4] && learners[4].subtotals.AttendanceSum;
            }
            if (j === 20) {
                return learners[5] && learners[5].subtotals.AttendanceSum;
            }

            // Practice Book Pts
            if (j === 1) {
                return learners[0] && learners[0].subtotals.PracticeBookSum;
            }
            if (j === 5) {
                return learners[1] && learners[1].subtotals.PracticeBookSum;
            }
            if (j === 9) {
                return learners[2] && learners[2].subtotals.PracticeBookSum;
            }
            if (j === 13) {
                return learners[3] && learners[3].subtotals.PracticeBookSum;
            }
            if (j === 17) {
                return learners[4] && learners[4].subtotals.PracticeBookSum;
            }
            if (j === 21) {
                return learners[5] && learners[5].subtotals.PracticeBookSum;
            }

            // Practice Assignment Pts
            if (j === 2) {
                return learners[0] && learners[0].subtotals.PracticeAssignmentSum;
            }
            if (j === 6) {
                return learners[1] && learners[1].subtotals.PracticeAssignmentSum;
            }
            if (j === 10) {
                return learners[2] && learners[2].subtotals.PracticeAssignmentSum;
            }
            if (j === 14) {
                return learners[3] && learners[3].subtotals.PracticeAssignmentSum;
            }
            if (j === 18) {
                return learners[4] && learners[4].subtotals.PracticeAssignmentSum;
            }
            if (j === 22) {
                return learners[5] && learners[5].subtotals.PracticeAssignmentSum;
            }

            // Extra Credit Pts
            if (j === 3) {
                return learners[0] && learners[0].subtotals.ExtraCreditSum;
            }
            if (j === 7) {
                return learners[1] && learners[1].subtotals.ExtraCreditSum;
            }
            if (j === 11) {
                return learners[2] && learners[2].subtotals.ExtraCreditSum;
            }
            if (j === 15) {
                return learners[3] && learners[3].subtotals.ExtraCreditSum;
            }
            if (j === 19) {
                return learners[4] && learners[4].subtotals.ExtraCreditSum;
            }
            if (j === 23) {
                return learners[5] && learners[5].subtotals.ExtraCreditSum;
            }

            return null;
        });

        const totals = groupMembers.map(gm => {
            if (gm) {
                const {
                    AttendanceSum,
                    PracticeBookSum,
                    PracticeAssignmentSum,
                    ExtraCreditSum,
                } = gm.subtotals;
                return AttendanceSum + PracticeBookSum + PracticeAssignmentSum + ExtraCreditSum;
            }
            return gm;
        });

        return { groupMembers, sessionRows, subtotals, totals };
    },
};

export default Component;
