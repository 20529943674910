import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import notification from 'cccisd-notification';
import axios from 'cccisd-axios';

import classListQuery from './classList.graphql';
import assignmentProgressQuery from './assignmentProgress.graphql';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd && window.cccisd.boilerplate;

function GroupFidelity() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [assignment, setAssignment] = useState(null);
    const [deploymentId, setDeploymentId] = useState(null);
    const [groups, setGroups] = useState([]);
    const [currentGroup, setCurrentGroup] = useState(null);

    useEffect(() => {
        getClassList().then(
            async ({
                groups: { classList },
                flows: {
                    deployment: {
                        assignment: { assignmentId: aId, surveylist },
                        deploymentId: depId,
                    },
                },
            }) => {
                const formattedClassList = formatClassList(classList);
                setAssignment({ id: aId, depId, flowlist: surveylist });
                setGroups(formattedClassList);
                if (!formattedClassList.length) {
                    setIsLoading(false);
                } else {
                    setCurrentGroup(
                        id && formattedClassList.find(g => g.groupId === Number(id))
                            ? formattedClassList.find(g => g.groupId === Number(id))
                            : formattedClassList[0]
                    );
                }
            }
        );
    }, []);

    useEffect(() => {
        if (currentGroup !== null) {
            setIsLoading(true);
            setDeploymentId(null);
            getAssignmentProgress({
                assignmentId: assignment.id,
                pawnId: currentGroup.pawnId,
            }).then(async data => {
                if (!data.roles.anyRole.assignmentProgress.deployment.deploymentId) {
                    getRepeatedDeployment({
                        aId: assignment.id,
                        depId: assignment.depId,
                        pawnId: currentGroup.pawnId,
                        pawnHash: currentGroup.pawnHash,
                        respondentHash: currentGroup.respondentHash,
                    });
                    setIsLoading(false);
                } else {
                    setDeploymentId(data.roles.anyRole.assignmentProgress.deployment.deploymentId);
                    setIsLoading(false);
                }
            });
        }
    }, [currentGroup]);

    async function getClassList() {
        const res = await apollo.query({
            query: classListQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: Fortress.user.acting.id,
            },
        });

        return res.data;
    }

    async function getAssignmentProgress({ assignmentId, pawnId }) {
        const res = await apollo.query({
            query: assignmentProgressQuery,
            fetchPolicy: 'network-only',
            variables: {
                assignmentId,
                pawnId,
                providerPawnId: Fortress.user.pawnId,
            },
        });

        return res.data;
    }

    async function getRepeatedDeployment({ aId, depId, pawnId, pawnHash, respondentHash }) {
        axios
            .get(
                Boilerplate.route('api.assignmentDeployment.repeated', {
                    deploymentId: depId,
                    pawnId,
                    hash: respondentHash,
                }),
                { params: { forceRepeat: true } }
            )
            .then(async ({ data: { data } }) => {
                axios
                    .post(Boilerplate.route('api.assignmentProgress.update'), {
                        pawnId,
                        deploymentId: data.deploymentId,
                        flowlist: 'default',
                    })
                    .then(async ({ data: { data: d } }) => {
                        const metricsData = {
                            sourceId: aId,
                            sourceType: 'assignment',
                            values: JSON.stringify({
                                provider_id: Fortress.user.pawnId,
                                provider_full_name: `${Fortress.user.firstName} ${Fortress.user.lastName}`,
                            }),
                            pawnId,
                            pawnHash,
                            assignmentProgressId: d.id,
                            responseSet: data.deploymentId,
                        };

                        await axios
                            .post(Boilerplate.route('metrics.api.v1.data.bulkUpdate'), metricsData)
                            .then(() => {
                                setDeploymentId(data.deploymentId);
                            })
                            .catch(err => {
                                notification({
                                    message: `${err.message}`,
                                    type: 'danger',
                                });
                            });
                    });
            });
    }

    function formatClassList(list) {
        return list.map(
            ({
                group: { groupId, label },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash, respondentHash },
                    },
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    respondentHash,
                };
            }
        );
    }

    const changeGroup = async e => {
        await setCurrentGroup(e);
    };

    if (!isLoading && groups.length === 0) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10rem' }}>
                <div>
                    <h2>
                        No groups were found.{' '}
                        <span role="img" aria-label="Thinking Emoji">
                            🤔
                        </span>
                    </h2>
                    <p>Please verify that groups have been created for this site.</p>
                </div>
            </div>
        );
    }

    if (!isLoading && id && !groups.find(c => c.groupId === Number(id))) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10rem' }}>
                <h2>
                    Invalid Group ID provided in the URL.{' '}
                    <span role="img" aria-label="Gasp Emoji">
                        😱
                    </span>
                </h2>
            </div>
        );
    }

    return (
        <Loader loading={isLoading}>
            {currentGroup && (
                <div style={{ margin: '1em 10em' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <h1>
                                <strong>Group Session Fidelity</strong>
                            </h1>
                        </div>
                        <div>
                            <div className="btn-group" role="group">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {currentGroup.label} <span className="caret" />
                                </button>
                                <div className="dropdown-menu" style={{ width: 'auto' }}>
                                    {groups.map(group => {
                                        return (
                                            <button
                                                type="button"
                                                className={classnames(
                                                    style.dropdownItem,
                                                    currentGroup.groupId === group.groupId &&
                                                        style.dropdownItemActive
                                                )}
                                                onClick={() => changeGroup(group)}
                                            >
                                                {group.label}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    {!isLoading && deploymentId && (
                        <DeploymentPlayer
                            deploymentId={deploymentId}
                            pawnId={currentGroup.pawnId}
                            pawnHash={currentGroup.pawnHash}
                            disableLayout
                            flowProps={{
                                variables: {
                                    provider: `${Fortress.user.firstName} ${Fortress.user.lastName}`,
                                    group: currentGroup.label,
                                },
                            }}
                        />
                    )}
                </div>
            )}
        </Loader>
    );
}

export default GroupFidelity;
