import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import {
    getPermissions,
    getPermissionFilter,
} from '../../../../../components/SessionData/permissions';
import groupQuery from '../group.graphql';
import Player from './player.js';

const Fortress = window.cccisd.fortress;

const Component = props => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;
        const permissions = getPermissions();
        const permissionFilter = getPermissionFilter();

        apollo
            .query({
                query: groupQuery,
                variables: {
                    pawnId,
                    permissions,
                    permissionFilter,
                },
            })
            .then(res => {
                setData(res.data);
            });
    }, []);

    return !data ? <Loader loading /> : <Player {...props} data={data} />;
};

export default Component;
