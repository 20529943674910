import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'cccisd-footer';
import SecondaryNav from '../../components/SecondaryNav';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: true,
    };

    render() {
        return (
            <div className={style.wrapper}>
                <div className={style.body}>
                    {this.props.showSecondaryNav && Fortress.auth() && (
                        <SecondaryNav
                            className={this.props.className}
                            navs={AppDefs.navs}
                            routes={AppDefs.routes}
                        />
                    )}
                    <div className={this.props.className}>{this.props.children}</div>
                </div>

                <div className={style.footer}>
                    <Footer className={this.props.className} />
                </div>
            </div>
        );
    }
}
