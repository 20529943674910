import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    fidelityAvg: {
        show: true,
        title: 'Session Fidelity Average',
        description: '',
    },
    groupSatisfaction: {
        show: true,
        title: 'Group Satisfaction',
        description: '',
    },
    programSatisfaction: {
        show: true,
        title: 'Program Satisfaction',
        description: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <h4>Callout 1</h4>
            <Field name="fidelityAvg.show" component={CccisdToggle} label="Show Callout 1?" />
            <Field name="fidelityAvg.title" component={CccisdInput} label="Title" />
            <Field name="fidelityAvg.description" component={CccisdInput} label="Description" />
            <h4>Callout 2</h4>
            <Field name="groupSatisfaction.show" component={CccisdToggle} label="Show Callout 2?" />
            <Field name="groupSatisfaction.title" component={CccisdInput} label="Title" />
            <Field
                name="groupSatisfaction.description"
                component={CccisdInput}
                label="Description"
            />
            <h4>Callout 3</h4>
            <Field
                name="programSatisfaction.show"
                component={CccisdToggle}
                label="Show Callout 3?"
            />
            <Field name="programSatisfaction.title" component={CccisdInput} label="Title" />
            <Field
                name="programSatisfaction.description"
                component={CccisdInput}
                label="Description"
            />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
