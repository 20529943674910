import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'pointsChart',
    label: 'Points Chart',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
