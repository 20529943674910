import React from 'react';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import TreeSelector from '../components/TreeSelector';

export default [
    {
        name: 'learnerSelector',
        label: 'Student Selector',
        initialValue: [null],
        component: props => {
            return (
                <Field name="learnerSelector">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <TreeSelector
                                field={field}
                                form={form}
                                tree={props.settings.tree}
                                title="Select Student"
                                single
                            />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: true,
    },
];
