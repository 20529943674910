/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import _round from 'lodash/round';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import Chart from './Chart';
import chartsQuery from '../../class.graphql';
import style from './style.css';
import { Html } from 'cccisd-wysiwyg';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { show, chart, description } = props.settings;
    const { query } = props;

    const [data, loading] = useQuery({
        query: query || chartsQuery,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const {
        provider1 = 'N/A',
        provider2 = 'N/A',
        provider1Data = [],
        provider2Data = [],
    } = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <>
                    <h1 className={style.title}>Model Implementation</h1>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>{provider1}</h2>
                                    <Chart
                                        data={provider1Data}
                                        settings={{
                                            ...chart,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>{provider2}</h2>
                                    <Chart
                                        data={provider2Data}
                                        settings={{
                                            ...chart,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Html content={description} />
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { assignmentProgressList } = data ? data.flows : {};

        function getChartData({
            Sum_PrepSetup_1,
            Sum_PrepSetup_4,
            Sum_PrepSetup_7,
            Sum_SessionContent_1,
            Sum_SessionContent_4,
            Sum_SessionContent_7,
            Sum_SettingFactor_1,
            Sum_SettingFactor_4,
            Sum_SettingFactor_7,
            Sum_EffectiveStrategies_1,
            Sum_EffectiveStrategies_4,
            Sum_EffectiveStrategies_7,
            Sum_BuildingConnections_1,
            Sum_BuildingConnections_4,
            Sum_BuildingConnections_7,
        }) {
            function getRate(sum) {
                return _round((Number(sum) / 7) * 100, 1);
            }

            return [
                {
                    id: 'Preparation & Setup',
                    'Session 1': getRate(Sum_PrepSetup_1),
                    'Session 4': getRate(Sum_PrepSetup_4),
                    'Session 7': getRate(Sum_PrepSetup_7),
                },
                {
                    id: 'Session Content',
                    'Session 1': getRate(Sum_SessionContent_1),
                    'Session 4': getRate(Sum_SessionContent_4),
                    'Session 7': getRate(Sum_SessionContent_7),
                },
                {
                    id: 'Setting Factor',
                    'Session 1': getRate(Sum_SettingFactor_1),
                    'Session 4': getRate(Sum_SettingFactor_4),
                    'Session 7': getRate(Sum_SettingFactor_7),
                },
                {
                    id: 'Effective Strategies',
                    'Session 1': getRate(Sum_EffectiveStrategies_1),
                    'Session 4': getRate(Sum_EffectiveStrategies_4),
                    'Session 7': getRate(Sum_EffectiveStrategies_7),
                },
                {
                    id: 'Building Connections',
                    'Session 1': getRate(Sum_BuildingConnections_1),
                    'Session 4': getRate(Sum_BuildingConnections_4),
                    'Session 7': getRate(Sum_BuildingConnections_7),
                },
            ];
        }

        return {
            provider1:
                assignmentProgressList[0] && assignmentProgressList[0].provider.ProviderFullName,
            provider2:
                assignmentProgressList[1] && assignmentProgressList[1].provider.ProviderFullName,
            provider1Data:
                assignmentProgressList[0] && getChartData(assignmentProgressList[0].sums),
            provider2Data:
                assignmentProgressList[1] && getChartData(assignmentProgressList[1].sums),
        };
    },
};

export default Component;
