import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'programOutcomesProfile',
    label: 'Program Outcomes Profile',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
