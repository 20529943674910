import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import widgets from '../widgets';
import filterFields from '../filterFields.js';
import ReportHeader from '../components/ReportHeader';
import style from './style.css';

const Component = props => {
    const {
        calloutsGlobalScore,
        chartsCoreDomainRatings,
        chartsModelImplementation,
        calloutsSatisfaction,
        chartsSatisfaction,
        commentsFidelity,
    } = props.widgets;
    const {
        body: { heading },
    } = props.settings;

    return (
        <Report>
            <Page>
                <div className={style.wrapper}>
                    <ReportHeader
                        settings={props.settings}
                        filters={props.filters}
                        isPreview={props.isPreview}
                        data={props.data}
                        individuals
                    />
                    {calloutsGlobalScore}
                    <div className={style.reportBodyText}>
                        <Html content={heading} />
                    </div>
                </div>
            </Page>
            <Page>
                <div className={style.wrapper}>{chartsCoreDomainRatings}</div>
            </Page>
            <Page>
                <div className={style.wrapper}>{chartsModelImplementation}</div>
            </Page>
            <Page>
                <div className={style.wrapper}>
                    {calloutsSatisfaction}
                    {chartsSatisfaction}
                </div>
            </Page>
            {commentsFidelity}
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

export default reportTemplatePlayer({
    reportHandle: 'sessionLogReport',
    widgets,
    getFilterFields: props =>
        filterFields.map(field =>
            field.name === 'classSelector'
                ? {
                      ...field,
                      tree: props.data.groups.classList.map(d => {
                          return {
                              label: d.group.label,
                              value: Number(d.childRoles.metricspawn.pawn.pawnId),
                          };
                      }),
                  }
                : field
        ),
})(Component);
