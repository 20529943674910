/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from './Chart';
import style from './style.css';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { show, chart } = props.settings;

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setHidden(true);
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, [props.filters.classSelector[0]]);

    const { session1Data, session4Data, session7Data, chartKeys = [] } = props.convertDataStructure(
        props.widgetProps,
        props.filters.classSelector[0]
    );

    return (
        <>
            {show && (
                <>
                    <h1 className={style.title}>Core Domain Ratings</h1>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>Session 1</h2>
                                    <Chart
                                        data={session1Data}
                                        settings={{
                                            ...chart,
                                            keys: chartKeys,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>Session 4</h2>
                                    <Chart
                                        data={session4Data}
                                        settings={{
                                            ...chart,
                                            keys: chartKeys,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>Session 7</h2>
                                    <Chart
                                        data={session7Data}
                                        settings={{
                                            ...chart,
                                            keys: chartKeys,
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: (data, groupId) => {
        const genFakeData = () => {
            return [
                {
                    id: 'Engagement',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Setting Factor',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Effective Strategies',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Building Connections',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
            ];
        };

        if (!groupId) {
            return {
                session1Data: genFakeData(),
                session4Data: genFakeData(),
                session7Data: genFakeData(),
                chartKeys: ['Youth 1', 'Youth 2', 'Youth 3'],
            };
        }

        const { classList } = data ? data.groups : {};

        const group = groupId
            ? classList.find(g => g.childRoles.metricspawn.pawn.pawnId === Number(groupId))
            : {};
        const groupMembers = group.childRoles ? group.childRoles.learnerList : [];

        function getChartData(sessionNumber) {
            return [
                {
                    id: 'Engagement',
                    ...groupMembers.reduce((a, c) => {
                        return {
                            ...a,
                            [c.name]: Number(
                                c[`s${sessionNumber}`].devTags[`EngagementSum_${sessionNumber}`]
                            ),
                        };
                    }, {}),
                },
                {
                    id: 'Setting Factor',
                    ...groupMembers.reduce((a, c) => {
                        return {
                            ...a,
                            [c.name]: Number(
                                c[`s${sessionNumber}`].devTags[`SettingFactor_${sessionNumber}`]
                            ),
                        };
                    }, {}),
                },
                {
                    id: 'Effective Strategies',
                    ...groupMembers.reduce((a, c) => {
                        return {
                            ...a,
                            [c.name]: Number(
                                c[`s${sessionNumber}`].devTags[
                                    `EffectiveStrategies_${sessionNumber}`
                                ]
                            ),
                        };
                    }, {}),
                },
                {
                    id: 'Building Connections',
                    ...groupMembers.reduce((a, c) => {
                        return {
                            ...a,
                            [c.name]: Number(
                                c[`s${sessionNumber}`].devTags[
                                    `BuildingConnections_${sessionNumber}`
                                ]
                            ),
                        };
                    }, {}),
                },
            ];
        }

        return {
            session1Data: getChartData('1'),
            session4Data: getChartData('4'),
            session7Data: getChartData('7'),
            chartKeys: groupMembers.map(gm => gm.name),
        };
    },
};

export default Component;
