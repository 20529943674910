/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import calloutsQuery from './calloutsQuery.graphql';
import Callout from './Callout';
import _round from 'lodash/round';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;

    const [data, loading] = useQuery({
        query: props.query ? props.query : calloutsQuery,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const { mood, riskFactors, copingSkills } = calloutsData;

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    {props.settings.mood.show && (
                        <Callout
                            handle="mood"
                            settings={{
                                ...props.settings.mood,
                                colors: props.settings.colors,
                            }}
                            data={mood}
                        />
                    )}
                    {props.settings.riskFactors.show && (
                        <Callout
                            handle="riskFactors"
                            settings={{
                                ...props.settings.riskFactors,
                                colors: props.settings.colors,
                            }}
                            data={riskFactors}
                        />
                    )}
                    {props.settings.copingSkills.show && (
                        <Callout
                            handle="copingSkills"
                            settings={{
                                ...props.settings.copingSkills,
                                colors: props.settings.colors,
                            }}
                            data={copingSkills}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        const { phase1_1 = { devTags: {} }, phase1_2 = { devTags: {} } } =
            data.roles.learnerList[0] || {};
        return {
            mood: {
                score: {
                    label: 'Raw Score',
                    value: phase1_1.devTags.CDI_Sum || 'N/A',
                    descriptor: '(>13 considered eligible)',
                },
                riskLevel: {
                    label: 'Risk Level',
                    value: phase1_1.devTags.CDI_Risk_Category
                        ? phase1_1.devTags.CDI_Risk_Category.toUpperCase()
                        : 'N/A',
                    descriptor: '(None, Medium, High)',
                },
            },
            riskFactors: {
                cogErr: {
                    label: 'Cog. Errors',
                    value: _round(phase1_2.devTags.Risk_CogErr_1_2, 2) || 'N/A',
                    descriptor: '(No-risk comparison group mean: 1.7)',
                },
                emoReact: {
                    label: 'Emo. React.',
                    value: _round(phase1_2.devTags.Risk_EmoReact_1_2, 2) || 'N/A',
                    descriptor: '(No-risk comparison group mean: 1.1)',
                },
            },
            copingSkills: {
                primary: {
                    label: 'Primary',
                    value: _round(phase1_2.devTags.CopPrimary_1_2, 2) || 'N/A',
                    descriptor: '(No-risk comparison group mean: 3.5)',
                },
                secondary: {
                    label: 'Secondary',
                    value: _round(phase1_2.devTags.CopSecondary_1_2, 2) || 'N/A',
                    descriptor: '(No-risk comparison group mean: 3.2)',
                },
            },
        };
    },
};

export default Component;
