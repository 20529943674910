import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import widgets from '../widgets';
import filterFields from '../filterFields.js';
import ReportHeader from '../components/ReportHeader';
import style from './style.css';

const Component = props => {
    const { summaryCallouts, summaryCharts, topFiveProblems } = props.widgets;
    const {
        body: { heading },
    } = props.settings;

    return (
        <Report>
            <Page>
                <div className={style.wrapper}>
                    <ReportHeader
                        settings={props.settings}
                        filters={props.filters}
                        isPreview={props.isPreview}
                        data={props.data}
                        individuals
                    />
                    <div className={style.reportBodyText}>
                        <Html content={heading} />
                    </div>
                    {summaryCallouts}
                    {summaryCharts}
                    {topFiveProblems}
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

export default reportTemplatePlayer({
    reportHandle: 'baselineAssessments',
    widgets,
    getFilterFields: props =>
        filterFields.map(field =>
            field.name === 'learnerSelector'
                ? {
                      ...field,
                      tree: props.data.groups.classList
                          .filter(c => c.childRoles.learnerList.length > 0)
                          .map(d => {
                              return {
                                  label: d.group.label,
                                  value: `${d.group.groupId}:${d.group.label}`,
                                  children: d.childRoles.learnerList.map(l => {
                                      return {
                                          label: l.fields.firstName,
                                          value: l.pawn.pawnId,
                                      };
                                  }),
                              };
                          }),
                  }
                : field
        ),
})(Component);
