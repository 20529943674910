import React from 'react';
import moment from 'moment';

import Modal from 'cccisd-modal';
import { Formik, Form, Field, CccisdSelect, CccisdDatepicker } from 'cccisd-formik';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';

import IconEdit from 'cccisd-icons/pencil';
import IconInfo from 'cccisd-icons/info2';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

async function handleSubmit({ pawnId, pawnHash, values, closeModal, loadData }) {
    await axios.put(
        Boilerplate.route('api.nexus.pawn.update', {
            pawn: pawnId,
        }),
        {
            pawnHash,
            youthAssent: values.youthAssent,
            youthAssentDateReceived:
                values.youthAssent !== null ? values.youthAssentDateReceived : null,
        }
    );

    closeModal && closeModal();
    loadData && loadData();
}

export default function YouthAssentModal({
    loadData,
    row: {
        'pawn.pawnId': pawnId,
        'pawn.pawnHash': pawnHash,
        youthAssent,
        'fields.youthAssentDateReceived': youthAssentDateReceived,
    },
}) {
    const labelType = getLabelType();

    function getLabelType() {
        if (youthAssent === '0') {
            return 'danger';
        }
        if (youthAssent === '1') {
            return 'success';
        }
        return 'default';
    }

    function getStatusString() {
        if (youthAssent === '1') {
            `Yes - ${
                youthAssentDateReceived
                    ? moment(youthAssentDateReceived).format('MM/DD/YYYY')
                    : 'No date provided'
            }`;
        }
        if (youthAssent === '0') {
            `No - ${
                youthAssentDateReceived
                    ? moment(youthAssentDateReceived).format('MM/DD/YYYY')
                    : 'No date provided'
            }`;
        }
        if (youthAssent === 'NA') {
            return 'Not yet received';
        }
    }

    return Fortress.user.acting.role.handle === 'orgAdmin' ? (
        <Modal
            title="Youth Assent"
            trigger={
                <button type="button" className={`btn btn-xs btn-${labelType}`}>
                    <strong>
                        <IconEdit spaceRight />
                        {getStatusString()}
                    </strong>
                </button>
            }
            render={({ closeModal }) => {
                return (
                    <Formik
                        onSubmit={values =>
                            handleSubmit({ pawnId, pawnHash, values, closeModal, loadData })
                        }
                        initialValues={{ youthAssent, youthAssentDateReceived }}
                    >
                        {({ values, isSubmitting, submitForm }) => (
                            <Form>
                                <section>
                                    <article className="alert alert-info" role="alert">
                                        <IconInfo spaceRight />
                                        <strong>Heads up!</strong>
                                        <span>
                                            {' '}
                                            For the 2020/2021 school year, the DePaul team will
                                            enter Youth Assent data.
                                        </span>
                                    </article>
                                </section>
                                <section>
                                    <Field
                                        component={CccisdSelect}
                                        name="youthAssent"
                                        label="Youth Assent Decision"
                                        options={[
                                            { label: 'Yes', value: '1' },
                                            { label: 'No', value: '0' },
                                            { label: 'Not yet received', value: 'NA' },
                                        ]}
                                    />
                                    {values.youthAssent !== 'NA' && (
                                        <Field
                                            component={CccisdDatepicker}
                                            name="youthAssentDateReceived"
                                            label="Date Assent Decision was Received"
                                            placeholder="MM/DD/YYYY"
                                        />
                                    )}
                                </section>
                                <section
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: '1em',
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() => closeModal()}
                                    >
                                        Cancel
                                    </button>
                                    <Button
                                        title="Submit"
                                        type="submit"
                                        isLoading={isSubmitting}
                                        className="btn btn-primary"
                                        onClick={() => submitForm()}
                                    />
                                </section>
                            </Form>
                        )}
                    </Formik>
                );
            }}
        />
    ) : (
        <span className={`label label-${labelType}`}>{getStatusString()}</span>
    );
}
