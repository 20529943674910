import React from 'react';
import Color from 'color';
import moment from 'moment';
import IconChart from 'cccisd-icons/chart';
import style from './style.css';

const ReportHeader = props => {
    const {
        header: {
            title,
            description,
            subtitle,
            learnerText,
            gradeText,
            referralInterviewerText,
            referralInterviewDateText,
            preTreatmentInterviewerText,
            preTreatmentInterviewDateText,
            createdDateText,
        },
        colors,
    } = props.settings;

    const selectedLearner = props.data.groups.classList
        .reduce((a, c) => {
            return [...a, ...c.childRoles.learnerList];
        }, [])
        .find(
            l =>
                String(l.pawn.pawnId) ===
                (props.filters.learnerSelector ? props.filters.learnerSelector[0] : null)
        );

    return (
        <>
            <div className={style.header}>
                <div className={style.headerTop}>
                    <div
                        className={style.topLeft}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div className={style.headerTitle}>{title}</div>
                    </div>
                    <div
                        className={style.topCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.topCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).lighten(0.1),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div className={style.topRight} style={colors ? { color: colors.primary } : {}}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{referralInterviewDateText} </span>
                            {(selectedLearner &&
                                selectedLearner.phase1_1.devTags.InterviewDate_1_1 &&
                                moment(selectedLearner.phase1_1.devTags.InterviewDate_1_1).format(
                                    'MMMM Do YYYY'
                                )) ||
                                'N/A'}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {preTreatmentInterviewDateText}{' '}
                            </span>
                            {(selectedLearner &&
                                selectedLearner.phase1_2.devTags.InterviewDate_1_2 &&
                                moment(selectedLearner.phase1_2.devTags.InterviewDate_1_2).format(
                                    'MMMM Do YYYY'
                                )) ||
                                'N/A'}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{createdDateText} </span>
                            {moment(new Date()).format('MMMM Do YYYY')}
                        </div>
                    </div>
                </div>
                <div className={style.headerBot}>
                    <div className={style.botLeft}>
                        <div
                            className={style.description}
                            style={colors ? { color: colors.primary } : {}}
                        >
                            {description}
                        </div>
                    </div>
                    <div
                        className={style.botCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.botCenterShadow}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.1),
                                      }
                                    : {}
                            }
                        />
                        <div
                            className={style.botCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.05),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div
                        className={style.botRight}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{subtitle}</div>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                            {selectedLearner && selectedLearner.ancestorGroups.site.group.label}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={style.subheader}
                style={
                    colors
                        ? {
                              color: colors.primary,
                          }
                        : {}
                }
            >
                <div className={style.subheaderText}>
                    <div>
                        <span
                            style={
                                props.individuals
                                    ? { fontWeight: 'bold', fontSize: '1.4em' }
                                    : { fontWeight: 'bold' }
                            }
                        >
                            {learnerText}{' '}
                        </span>
                        <span style={props.individuals ? { fontSize: '1.4em' } : {}}>
                            {selectedLearner &&
                                `${selectedLearner.fields.firstName} ${selectedLearner.fields.lastName[0]}.`}
                        </span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{gradeText} </span>
                        {selectedLearner && selectedLearner.fields.grade}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{referralInterviewerText} </span>
                        {selectedLearner && selectedLearner.phase1_1.devTags.Interviewer_1_1}
                    </div>
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{preTreatmentInterviewerText} </span>
                        {selectedLearner && selectedLearner.phase1_2.devTags.Interviewer_1_2}
                    </div>
                </div>
                <div
                    className={style.subheaderIcon}
                    style={props.individuals ? { fontSize: '2em' } : {}}
                >
                    <IconChart />
                </div>
            </div>
        </>
    );
};

export default ReportHeader;
