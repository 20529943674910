import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import classnames from 'classnames';
import Item from './item.js';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;

export default withRouter(
    class SecondaryNav extends React.Component {
        static propTypes = {
            navs: PropTypes.array,
            routes: PropTypes.array,
            isInverse: PropTypes.bool,

            /**
             * Location from react router withRouter HoC
             */
            location: PropTypes.object,
            match: PropTypes.object,
            className: PropTypes.string,
            id: PropTypes.string,
        };

        static defaultProps = {
            isInverse: true,
        };

        shouldComponentUpdate = (nextProps, nextState) => {
            if (this.props.location.pathname !== nextProps.location.pathname) {
                return true;
            }
            return false;
        };

        render() {
            const { navs, routes } = this.props;
            const routesWithRoleDataFields = routes.filter(route => route.roleDataFields);
            const routesWithoutRoleDataFields = routes.filter(route => !route.roleDataFields);
            var navItems = [];

            // construct nav items
            navs[1].nav.forEach(defItem => {
                const hasPermission =
                    routesWithoutRoleDataFields.some(route => route.handle === defItem.handle) ||
                    routesWithRoleDataFields
                        .find(route => route.handle === defItem.handle)
                        .roleDataFields.every(field => Fortress.user.acting.role_data[field]);

                if (hasPermission) {
                    let constructedItem = {
                        handle: defItem.handle,
                        render: defItem.render,
                    };

                    constructedItem.render.params.activeRegex = new RegExp(
                        constructedItem.render.params.activeRegex
                    );
                    constructedItem.url = _filter(
                        this.props.routes,
                        r => r.handle === defItem.handle
                    )[0].url;
                    navItems.push(constructedItem);
                }
            });

            return (
                <nav
                    className={classnames({
                        navbar: true,
                        'navbar-default': !this.props.isInverse,
                        'navbar-inverse': this.props.isInverse,
                        'legos-navbar': !this.props.id,
                        [`legos-navbar-${this.props.id}`]: this.props.id,
                    })}
                    role="navigation"
                >
                    <div className={this.props.className}>
                        <ul className={classnames(style.nav, 'nav', 'navbar-nav')}>
                            {navItems.map(item => {
                                return (
                                    <Item
                                        key={item.handle}
                                        item={item}
                                        active={
                                            this.props.location.pathname.match(
                                                item.render.params.activeRegex
                                            ) !== null &&
                                            _find(
                                                this.props.routes,
                                                r => r.url === this.props.match.path
                                            )
                                                ? true
                                                : false
                                        }
                                        {...this.props}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </nav>
            );
        }
    }
);
