import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';

import classListQuery from './classList.graphql';
import { getPermissions, getPermissionFilter } from './permissions';

var Fortress = window.cccisd.fortress;

function OutcomesData() {
    const [classList, setClassList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const data = await getClassList();
        const formattedClassList = formatClassList(data.groups.classList);

        await setClassList(formattedClassList);

        setIsLoading(false);
    }

    async function getClassList() {
        let pawnId = Fortress.user.acting.id;

        const permissions = getPermissions();
        const permissionFilter = getPermissionFilter();

        const res = await apollo.query({
            query: classListQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
                permissions,
                permissionFilter,
            },
        });

        return res.data;
    }

    function formatClassList(groups) {
        return groups.map(
            ({
                group: { groupId, label },
                fields: { idNumber },
                ancestorGroups: {
                    site: {
                        group: { label: siteLabel },
                    },
                },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: {
                        variables: {
                            site: siteLabel,
                            group: label,
                            idNumber,
                        },
                    },
                };
            }
        );
    }

    return (
        !isLoading && (
            <OutcomesDataEntry
                questprojectIds={[1]}
                groups={classList}
                title="Participant Outcomes"
                description="Outcome assessments enable programs to explore the degree to which the services, content, and activities delivered to participants are having the desired impact. To explore this data further, go to the Report Center to view corresponding reports."
                tableColumns={[
                    {
                        name: 'fields.participantId',
                        label: 'Act & Adapt ID',
                        sort: true,
                        filter: true,
                    },
                    { name: 'fields.firstName', label: 'First Name', sort: true, filter: true },
                    {
                        name: 'fields.lastName',
                        label: 'Last Name (Initial)',
                        sort: true,
                        filter: true,
                        render: ({ row: { 'fields.lastName': lastName } }) => {
                            return lastName.split('')[0] + '.' || '';
                        },
                    },
                ]}
                tableFields={['participantId', 'firstName', 'lastName']}
                modalTitleField="firstName"
            />
        )
    );
}

export default OutcomesData;
