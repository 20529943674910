import React from 'react';

import useScoreLevel from './useScoreLevel';

export default ({ scoreTag }) => {
    return ({ row, value }) => {
        const { score, level, labelType } = useScoreLevel(row, scoreTag);

        return score && level ? (
            <span className={`label label-${labelType || 'default'}`}>{`${score} - ${level}`}</span>
        ) : (
            value
        );
    };
};
