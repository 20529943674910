import React from 'react';
import { Field, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    chart: {
        xLabel: '',
        yLabel: 'Score',
        showLegend: false,
        targetLineValue: '',
        verticalScaleMin: '0',
        verticalScaleMax: '80',
        scoreSuffix: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Chart?" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
