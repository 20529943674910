/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery';
import flowQuery from '../flows.graphql';
import Chart from './Chart';
import style from './style.css';
import { Html } from 'cccisd-wysiwyg';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { show, chart, description } = props.settings;
    const { query } = props;

    const [data, loading] = useQuery({
        query: query || flowQuery,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const { grpSatData = [], prgSatData = [], chartKeys = [] } = props.convertDataStructure(
        { ...props.widgetProps, ...data },
        props.filters.classSelector[0]
    );

    return (
        <>
            {show && (
                <>
                    <h1 className={style.title}>Group & Program Satisfaction</h1>
                    <Html content={description} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>Group Satisfaction</h2>
                                    <Chart
                                        data={grpSatData}
                                        settings={{
                                            ...chart,
                                            keys: chartKeys,
                                            tickValues: [0, 1, 2, 3, 4, 5],
                                            margin: {
                                                left: 350,
                                                bottom: 100,
                                                right: 50,
                                            },
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className={style.overallTable}>
                            {hidden ? (
                                <div />
                            ) : (
                                <>
                                    <h2>Program Satisfaction</h2>
                                    <Chart
                                        data={prgSatData}
                                        settings={{
                                            ...chart,
                                            keys: chartKeys,
                                            tickValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                                            verticalScaleMax: 10,
                                            margin: {
                                                left: 250,
                                                bottom: 100,
                                                right: 50,
                                            },
                                            legends: [
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: -100,
                                                    translateY: 60,
                                                    itemsSpacing: 80,
                                                    itemWidth: 75,
                                                    itemHeight: 10,
                                                    itemDirection: 'left-to-right',
                                                    symbolSize: 10,
                                                },
                                            ],
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: (data, groupId) => {
        const genFakeGrpSatData = () => {
            return [
                {
                    id: 'Q1',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q2',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q3',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q4',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q5',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q6',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q7',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q8',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q9',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
            ];
        };

        const genFakePrgSatData = () => {
            return [
                {
                    id: 'Q1',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q2',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q3',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q4',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q5',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
                {
                    id: 'Q6',
                    'Youth 1': 2,
                    'Youth 2': 1.5,
                    'Youth 3': 2.5,
                },
            ];
        };

        if (!groupId) {
            return {
                grpSatData: genFakeGrpSatData(),
                prgSatData: genFakePrgSatData(),
                chartKeys: ['Youth 1', 'Youth 2', 'Youth 3'],
            };
        }

        const { classList } = data ? data.groups : {};
        const { flow } = data ? data.flows : {};

        const group = groupId
            ? classList.find(g => g.childRoles.metricspawn.pawn.pawnId === Number(groupId))
            : {};
        const groupMembers = group.childRoles ? group.childRoles.learnerList : [];

        function getGrpSatChartData(gm) {
            const items = flow.grpSat.content.rows.map(r => r.label.replace(/&amp;/g, '&'));

            return items
                .map((item, i) => {
                    return {
                        id: item.length > 75 ? item.slice(0, 75).concat('...') : item,
                        ...gm.reduce((a, c) => {
                            return {
                                ...a,
                                [c.name]: Number(c.s10.grpSat[`GrpSat_Q${i + 1}`]),
                            };
                        }, {}),
                    };
                })
                .reverse();
        }

        function getPrgSatChartData(gm) {
            const items = flow.prgSat.content.rows.map(r => r.label.replace(/&amp;/g, '&'));

            return items
                .map((item, i) => {
                    return {
                        id: item,
                        ...gm.reduce((a, c) => {
                            return {
                                ...a,
                                [c.name]: Number(c.s10.prgSat[`PrgSat_Q${i + 1}`]),
                            };
                        }, {}),
                    };
                })
                .reverse();
        }

        return {
            grpSatData: groupMembers.length > 0 ? getGrpSatChartData(groupMembers) : [],
            prgSatData: groupMembers.length > 0 ? getPrgSatChartData(groupMembers) : [],
            chartKeys: groupMembers.map(gm => gm.name),
        };
    },
};

export default Component;
