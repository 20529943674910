import React, { useRef, useState, useEffect } from 'react';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import style from './style.css';
import Button from 'cccisd-click-button';

const TreeSelector = ({
    form,
    field,
    title = 'Select',
    tree = [],
    modalProps = {},
    icons = {},
    single = false,
}) => {
    const modal = useRef(null);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodes, setNodes] = useState(false);
    const [leaves, setLeaves] = useState(false);

    useEffect(() => {}, [checked]);

    const sortNodesLeaves = t => {
        let n = [];
        let l = [];

        const recurseTree = branches => {
            for (let branch of branches) {
                if (!branch.children) {
                    l.push(String(branch.value));
                    continue;
                }
                n.push(String(branch.value));
                recurseTree(branch.children);
            }
        };

        recurseTree(t);
        setNodes(n);
        setLeaves(l);
    };

    useEffect(() => {
        sortNodesLeaves(tree);
    }, []);

    const handleCheck = c => {
        single ? handleSingleCheck(c) : handleMultiCheck(c);
    };

    const handleSingleCheck = c => {
        setChecked(c.filter(v => v !== checked[0]));
    };

    const handleMultiCheck = c => {
        setChecked(c);
    };

    const setSelection = () => {
        form.setFieldValue(field.name, checked);
    };

    const checkAll = () => {
        setChecked(leaves);
    };

    const uncheckAll = () => {
        setChecked([]);
    };

    const expandAll = () => {
        setExpanded(nodes);
    };

    const collapseAll = () => {
        setExpanded([]);
    };

    const closeModal = () => {
        setSelection();
        modal.current.close();
    };

    return (
        <div className={style.flex}>
            <Modal
                title={title}
                size="large"
                trigger={
                    <button type="button" className={classnames('btn', 'btn-primary', style.btn)}>
                        <IconSelect spaceRight />
                        {title}
                    </button>
                }
                {...modalProps}
                ref={modal}
            >
                <div className={style.flex}>
                    <CheckboxTree
                        nodes={tree}
                        onlyLeafCheckboxes={single}
                        checked={checked}
                        expanded={expanded}
                        onCheck={handleCheck}
                        onExpand={e => setExpanded(e)}
                        icons={{
                            check: <IconCheck />,
                            uncheck: <IconUncheck />,
                            halfCheck: <IconHalfcheck />,
                            expandClose: <IconExpandClose />,
                            expandOpen: <IconExpandOpen />,
                            expandAll: <IconExpandOpen />,
                            collapseAll: <IconExpandClose />,
                            parentClose: <IconParentClose />,
                            parentOpen: <IconParrentOpen />,
                            leaf: <IconLeaf />,
                            ...icons,
                        }}
                    />
                    <div className={style.buttonBox}>
                        <div className={style.flex}>
                            {!single && (
                                <Button
                                    className={classnames('btn', 'btn-default', style.button)}
                                    title="Select All"
                                    onClick={checkAll}
                                />
                            )}
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Deselect All"
                                onClick={uncheckAll}
                            />
                        </div>
                        <div className={style.flex}>
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Expand All"
                                onClick={expandAll}
                            />
                            <Button
                                className={classnames('btn', 'btn-default', style.button)}
                                title="Collapse All"
                                onClick={collapseAll}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.modalFooter}>
                    <Button title="Done" className="btn btn-primary" onClick={closeModal} />
                </div>
            </Modal>
        </div>
    );
};

export default TreeSelector;
