import axios from 'cccisd-axios';
import notification from 'cccisd-notification';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

export default async function handleSubmit({ data, decision, values, closeModal, loadData }) {
    const screeningComments = (values && (values.commentsIfOther || values.comments)) || null;
    const decisionNotificationMap = {
        include: 'included',
        exclude: 'archived',
        unexclude: 'unarchived',
    };

    const metricsData = {
        sourceId: data['screening.assignmentId'],
        sourceType: 'assignment',
        values: JSON.stringify({
            screener: decision === 'unexclude' ? null : Fortress.user.acting.id,
            screening_result: decision === 'unexclude' ? null : decision,
            screening_comments: decision === 'unexclude' ? null : screeningComments,
        }),
        pawnId: data['pawn.pawnId'],
        pawnHash: data['pawn.pawnHash'],
        assignmentProgressId: data['screening.assignmentProgressId'],
        responseSet: data['screening.deployment.deploymentId'],
        pending: decision === 'include' ? false : true,
    };

    await axios
        .post(Boilerplate.route('metrics.api.v1.data.bulkUpdate'), metricsData)
        .then(() => {
            notification({
                message: `Youth has been successfully ${decisionNotificationMap[decision]}.`,
                type: 'success',
            });
        })
        .catch(err => {
            notification({
                message: `Attempt to ${decision} youth failed. Error: ${err.message}`,
                type: 'danger',
            });
        });

    closeModal && closeModal();
    loadData && loadData();
}
