import React from 'react';
import { Field, CccisdToggle, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    chart: {
        verticalScaleMin: 0,
        verticalScaleMax: 5,
        keys: ['Session 1', 'Session 4', 'Session 7'],
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Chart?" />
            <Field name="description" component={CccisdWysiwyg} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
