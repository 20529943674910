import React from 'react';
import style from './style.css';

const ShowValue = props => {
    const { colors } = props;

    const renderValues = v => {
        if (v.preScore || v.postScore) {
            return (
                <>
                    {`PRE: ${v.preScore}`}
                    {`POST: ${v.postScore}`}
                </>
            );
        }
        return <>{v.value}</>;
    };

    return (
        <div className={style.wrapper} style={colors ? { color: colors.primary } : {}}>
            {Object.values(props.data).map(v => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontSize: '12px' }}>{v.label}</div>
                        <div style={{ fontSize: '24px' }}>{renderValues(v)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default ShowValue;
